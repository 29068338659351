import React from 'react'
import Labelmoney from '../labelmoney';
import LabelFecha from '../LabelFecha';

const FooterTicket=({Ticket, textSize})=> {
  const textsize = textSize || "";
  return( <div className={"flex flex-col mt-2 "+textsize}>
    <div className={"flex flex-row gap-2 "}>
      <div className='flex flex-col w-16'>
        <label>SubTotal:</label>
        <label>Descuento: </label>
        <label>Importe:</label>
      </div>
      <div>
        <Labelmoney className="w-16" twoDecimal={true}>{Ticket?.subtotal}</Labelmoney>
        <Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.subtotal - Ticket?.total)||0}</Labelmoney>
        <Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
      </div>
    </div>

    {Ticket?.pagos.length?<>
    <p className=' text-center font-bold mt-1'>Pagos</p>
    <div className={"flex flex-col  "+textsize}>
      <div className='flex flex-row gap-2 pb-1'>
        <div className="font-bold text-center w-16">Metodo</div>
        <div className="font-bold text-center w-16">Monto</div>
        <div className="font-bold text-center w-20">Fecha Pago</div>
      </div>
      {Ticket.pagos.map((pago,index)=>(<>
      <div className='flex flex-row gap-2'>
        <label className='w-16 break-words' >{pago.metodo}</label>
        <Labelmoney className="w-16 " twoDecimal={true}>{(pago?.excedente?pago.excedente:(pago.monto || 0))}</Labelmoney>
        <LabelFecha className="w-20 ">{pago.fecha}</LabelFecha>
      </div>
      </>))}
      <div className={"flex flex-row gap-2 "}>
        <div className='flex flex-col w-16'>
          {Ticket?.pagos.length?<label className="">Pagado:</label>:""}
          {Ticket?.total-Ticket?.recibido>0?<label className={""}>Restante:</label>:""}
          {Ticket?.total-Ticket?.recibido<0?<label>Cambio:</label>:""}
        </div>
        <div>
          {Ticket?.pagos.length?<Labelmoney className="w-16 " twoDecimal={true}>{(Ticket?.recibido?Ticket.recibido:Ticket?.pagado)}</Labelmoney>:""}
          {Ticket?.total-Ticket?.recibido>0?<Labelmoney className="w-16 " twoDecimal={true}>{Ticket?.total-Ticket?.recibido || 0}</Labelmoney>:""}
          {Ticket?.total-Ticket?.recibido<0?<Labelmoney className="w-16" twoDecimal={true}>{((Ticket?.total-Ticket?.recibido)*-1|| 0)}</Labelmoney>:""}
          </div>

      </div>

       

    </div>
    </>:""}


  </div>
  )
}
export default FooterTicket