import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import { Sucursal } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import BodyTicketCopia from "./BodyTicketCopia.jsx";
import HeaderTicket from "./HeaderTicket.jsx";
import FooterTicket from "./FooterTicket.jsx";
import { btnScale, btnTap } from "../../static/motionStyle.js";

const TicketCopia= ({ Ticket, delRuta }) => {
  const [texto,setTexto] = useState(false);

  useEffect(() => { window.print();}, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            <motion.button
            whileHover={btnScale}
            whileTap={btnTap }
            onClick={(e) => { setTexto(!texto); }}
            className="text-lg justify-self-end font-black uppercase"
            >
              COPIA
            </motion.button>

            <motion.button
            whileHover={btnScale}
            whileTap={btnTap }
            onClick={(e) => {delRuta(); }}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <HeaderTicket Ticket={Ticket} copia={true} />
          <BodyTicketCopia Ticket={Ticket} Descripcion={texto} />
          <FooterTicket Ticket={Ticket}/>
          {Sucursal('ticket')?.productos.length?
            <div className="w-full text-center mt-3">
              <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
              {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
            </div>
          :""}
          {Sucursal('ticket')?.footer?
            <p className="w-full text-center mt-3">{Sucursal('ticket').footer}</p>
          :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketCopia);
