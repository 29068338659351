import React, { useState, useEffect, useRef } from "react";
import InputClientVenta from "../components/InputClientVenta";
import DatosClienteVenta from "../components/DatosClienteVenta";
import InputVenta from "../components/inputVenta";
import { connect } from "react-redux";
import Labelmoney from "../components/labelmoney";
import { CalculaPagos, getSucursal, moneyToValue, Sucursal, valueToMoney } from "../services/functions.js";
import Modalinfo from "../components/modal/info";
import Modaldanger from "../components/modal/danger";
import Modalsuccess from "../components/modal/success";
import TablaCredito from "../components/Tablacredito";
import { returnRuta, setRuta } from "../redux/Actions";
import Container from "../components/Container";
import { useUpdateVentaAsync } from "../Hooks/useApis";
import Modalgeneric from "../components/modal/generic";
import InputPrecioPanel from "../components/InputPrecioPanel";
import TableModal from "../components/modal/TableModal";
import Pendientes from "../components/Ventas/Pendientes";
import LabelFecha from "../components/LabelFecha";
import LoadingModal from "../components/modal/LoadingModal";
import { cloneDeep } from "lodash";
import {motion} from "framer-motion"
import { btnScale, btnScale8, btnTap } from "../static/motionStyle.js";

const Pago = ({ config }) => {
  const formasPagoLibres = config.formasPago;
   
  return (
    <tr className="h-12 lg:h-16">
    <td className="w-2/12">
      <div className="flex w-full px-2 items-center ">
        <button
        className="text-ambar-300 hover:text-red-400 text-xl mr-2"
        onClick={(e) => { config.deletepago(config.index); }}
        >
          <i className="fas fa-times-circle"></i>
        </button>
        <select
          className="border-form focus:bg-ambar-100 w-full"
          key={config.index+"forma"}
          name={"formas"}
          onChange={(e) => {
            let newdata = [...config.data];
            if(e.target.value==="EFECTIVO"){
               newdata[config.index].cuenta="CAJA";
            }else{
              newdata[config.index].cuenta="";
            }
              newdata[config.index].metodo = e.target.value;
            
            config.setdata(newdata);
          }}
          value={config.data[config.index]?.metodo}
        >
          <option key={0} value={""}></option>
          {formasPagoLibres().map((item, index) => (
            <option key={index + 1} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </td>
    <td className="px-2 w-6/12">
      <select
          className="border-form focus:bg-ambar-100 w-full"
          disabled={config.data[config.index]?.metodo=="EFECTIVO"}
          onChange={(e) => {
            let newdata = [...config.data];
            newdata[config.index].cuenta = e.target.value;
            config.setdata(newdata);
          }}
        >
          <option key={0} value={""}></option>
          {config.cuentas.map((item, index) => (
            <option key={index + 1} value={Object.keys(item)[0]+'/'+Object.values(item)[0]}>
              {Object.keys(item)[0]+' '+Object.values(item)[0]}
            </option>
          ))}
        </select>
    </td>
    <td className="w-2/12 px-1">
      <InputPrecioPanel
      className="bg-white focus:bg-ambar-100"
      form={true}
      min={0}
      defaultValue={config.data[config.index]?.monto}
      onKeyPress={(e,value)=>{
        if(e.key=="Enter"){ config.funcionPagar(e); }
      }}
      setValor={(valor)=>{
        let newdata = [...config.data];

        if (!isNaN(valor) ){ newdata[config.index].monto = valor; }
        config.setdata(newdata);
       }}
      />
    </td>
  </tr>
  );
};

const Caja = ({ VentaAPagar,Clientes, addClient,SetVentaAPagar,VentasCredito,addRuta,SetTicket,FormasP,Ruta,delRuta}) => {
  const [pagos, setpagos] = useState([{ monto: 0,metodo: "", cuenta: "",}]),
   [pin,setPin] =useState(''),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [disableBtnCobrar,setDisableBtnCobrar] = useState(false),
  [ventaDirecta,setVentaDirecta]=useState(),
  [statePendientes,setStatePendientes]= useState(),
  [modalLoading, setmodalLoading] = useState({}),
  [porPagar, setPorPagar] = useState(0),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [cobranza, setcobranza] = useState(false);

  const oldRuta =Ruta?.[Ruta.length-2];

  const openmodalLoading = (titulo) => {
    titulo= titulo||"Procesando Datos";
    setmodalLoading({ isOpen: true, titulo });
  };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  const navegar = (ruta) => {addRuta(ruta);};

  const handleKeyPress=(e)=>{
    if(e.key=="F9"){
      e.preventDefault();
      setStatePendientes(!statePendientes);
      return;
    }
  }

  function closeModaltabla(e) { setmodalTablaConfig({ isOpen: false });}
  function closeModalinfo(e) {
    setDisableBtnCobrar(false); 
    setmodalinfoConfig({ isOpen: false, botoncredito: false, inputPin:false});
  }
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  const borrarDatos = () => {
    addClient({});
    SetVentaAPagar({ total: 0, recibido:0,cambio:false, restante:0, importe:0 });
    VentasCredito([{}]);
    setpagos([
      {
        monto: 0.0,
        metodo: "",
        cuenta: ""
      },
    ]);
  };

  const hacerPago = async (newpagos) => {
    openmodalLoading();
    let mispagos = [];
    if (CalculaPagos(newpagos,true) != 0){
      for (let pago of newpagos) {
        const pay = {...pago,monto:pago.monto || 0};
        mispagos.push(pay);
      }
    }
    try {
      const resp = await useUpdateVentaAsync(VentaAPagar.id,mispagos);
      const ventaPagar = cloneDeep(VentaAPagar);
      const venta = cloneDeep(resp.data.Attributes);
      venta.items = resp.data.items;
  
      venta.cambio=ventaPagar.cambio||false;
      venta.recibido = ventaPagar.recibido;
      venta.restante= ventaPagar.restante;
      
      SetTicket(venta);
      borrarDatos();
      setDisableBtnCobrar(false);
      closemodalLoading();

      if(venta?.pagado>=venta?.total||ventaPagar.status=="PENDIENTE#"+getSucursal()){ navegar("ticketPagado"); }
      else{ navegar("ticketPagadoAbonos") }
    } catch (error) {
      setDisableBtnCobrar(false);
      closemodalLoading();
      console.log('Error=>',error)
    }
  };

  const sendDatapagar = (e) => {
    if(disableBtnCobrar){return}
    if(!validaPagos()){ return}
    const newVentaAPagar ={...VentaAPagar},copyPagos= cloneDeep(pagos);
    let totalpagado = 0 ,newPagado = 0,reci =0; 

    setDisableBtnCobrar(true);
    openmodalLoading();

    newPagado = CalculaPagos(pagos,true);
    reci = newPagado+ newVentaAPagar.pagado;
    totalpagado = (reci>VentaAPagar.importe?VentaAPagar.importe:reci);
    
    copyPagos.forEach(pago=>{pago.monto = moneyToValue(pago.monto);})

    if (reci > VentaAPagar.importe) {
      if(copyPagos.length===1){
        copyPagos[0].excedente = copyPagos[0].monto; 
        copyPagos[0].monto = VentaAPagar.total;
      }else{
        let otrosPagos = 0,pagoEfectivo=0;
        copyPagos.forEach(pago=>{
          if(pago.metodo=="EFECTIVO"){ pagoEfectivo = pago.monto; }
          else{ otrosPagos += pago.monto; }
        });
        const newEfe =VentaAPagar.total - otrosPagos;
        if(otrosPagos>VentaAPagar.total&&pagoEfectivo==0){
          const newMonto = copyPagos[copyPagos.length-1].monto;
          copyPagos[copyPagos.length-1].excedente = newMonto;
          copyPagos[copyPagos.length-1].monto = VentaAPagar.total - (otrosPagos-newMonto) ;
        }else if(otrosPagos<=VentaAPagar.total&&newEfe<pagoEfectivo){
          copyPagos.forEach(pago=>{
            if(pago.metodo=="EFECTIVO"){
              pago.excedente = pago.monto;
              pago.monto = newEfe;
            }
          })
        }else{ console.log('otrosPagos:',otrosPagos,'<==>',pagoEfectivo); }
      }
      newVentaAPagar.cambio = true;
      newVentaAPagar.enviarTk =true;
    } else if (reci < VentaAPagar.importe ) {
      closemodalLoading();
      let aunConCredito = VentaAPagar.importe - totalpagado <= Clientes.creditoRestante;
      let abono = (aunConCredito >0 && reci > 0)&&VentaAPagar?.status=="CREDITO#"+getSucursal();

      if (abono) {
        setmodalinfoConfig({
          isOpen: true,
          title: "Abono a Ticket",
          message: <p>Está seguro que desea abonar a esta venta a crédito?.</p>,
          botoncredito: true,
          botonconfirmar:false,
          textoBotonCredito: "Abonar a Ticket",
        });
      } else if (aunConCredito&&VentaAPagar?.status=="PENDIENTE#"+getSucursal()) {
        setPin("")
        setmodalinfoConfig({
          isOpen: true,
          pin:Clientes.pin,
          inputPin:true,
          title: "Venta a Credito",
          message: "Ingrese su PIN para confirmar venta a crédito.",
          botoncredito: false,
          botonconfirmar:true,
          textoBotonCredito: "Enviar a Credito",
        });
      } else {
        setmodalinfoConfig({
          isOpen: true,
          title: "Monto no valido",
          message: (
            <p key="msgCredit">
              No es posible convertir esta venta a crédito. El monto debe ser mayor a cero
            </p>
          ),
        });
        return;
      }
    } else { newVentaAPagar.enviarTk =true;  }
    newVentaAPagar.recibido = reci;
    newVentaAPagar.restante = VentaAPagar.importe - reci;
    newVentaAPagar.newPagos = copyPagos;
    SetVentaAPagar(newVentaAPagar);
  };

  const addpay = (e) => {
    e.preventDefault();
    setpagos(pagos.concat({ monto: 0.0, metodo: "", cuenta : "" }));
  };

  const deletePago = (index) => {
    let newPagos = [...pagos];
    let misPagos = [];
    for (let i = 0; i < newPagos.length; i++) {
      if (i !== index) {
        misPagos.push(newPagos[i]);
      }
    }
    setpagos(misPagos);
  };

  Set.prototype.difference = function (otherSet) {
    var differenceSet = [];
    for (var elem of this) {
      if (!otherSet.has(elem)) differenceSet.push(elem);
    }
    return differenceSet;
  };

  const validaPagos=()=>{
    let valido = true;
    if(!pagos.length||VentaAPagar.total === 0||pagos.filter(pago=>(pago.metodo=="")).length||pagos.filter(pago=>(pago.cuenta=="")).length){
      valido=false
    }
    return valido;
  }

  useEffect(() => {
    if(VentaAPagar?.total==0){return}
    const totalpagado = CalculaPagos(pagos,true);
    const aPagar = VentaAPagar.total - parseInt(totalpagado);
    setPorPagar(aPagar);
  }, [pagos,VentaAPagar]);


  useEffect(() => {
    if(!VentaAPagar?.enviarTk||!VentaAPagar?.newPagos){return}
    hacerPago(VentaAPagar.newPagos);
  }, [VentaAPagar?.enviarTk])
  

  useEffect(() => {
    if(!Clientes.venta){return;}
    setVentaDirecta(Clientes.venta)
  }, [])

  useEffect(()=>{
    if(!Clientes?.activo){return}
    const selects = document.getElementsByName("formas");
    selects[0]?.focus?selects[0].focus():"";
  },[Clientes])
  

  return (
    <div className="w-full h-full" tabIndex={"0"} onKeyDown={handleKeyPress}>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{"Procesando Pago"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig} >
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        {modalinfoConfig.inputPin?(<div className="flex justify-center w-full mb-2">
          <input 
          className="border-form w-full md:w-1/2" 
          type="password" 
          defaultValue=""
          onChange={(e) => {setPin(e.target.value)}}
          />
        </div>):null}
        <Modalinfo.Buttons>
          <>
            <button
              type="button"
              className="btn-gray2"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
            {modalinfoConfig.botoncredito ? (
              <button
                type="button"
                className="btn-green"
                onClick={(e) => {
                  if(e.detail>1){return}
                  closeModalinfo();
                  const newVentaAPagar = {...VentaAPagar};
                  newVentaAPagar.enviarTk =true;
                  SetVentaAPagar(newVentaAPagar);
                }}
              >
                {modalinfoConfig.textoBotonCredito}
              </button>
            ) : null}
            {modalinfoConfig.botonconfirmar ? (
              <button
                type="button"
                className="btn-blue"
                disabled={modalinfoConfig.pin != pin }
                onClick={(e) => {
                  if(e.detail>1){return;}
                  closeModalinfo();
                  const newVentaAPagar = {...VentaAPagar};
                  newVentaAPagar.enviarTk =true;
                  SetVentaAPagar(newVentaAPagar);
                }}
              >
                {modalinfoConfig.textoBotonCredito}
              </button>
            ) : null}
          </>
        </Modalinfo.Buttons>
      </Modalinfo>
      
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Historial de pagos</Modalgeneric.Title>
          <TableModal searchinput={false}
            columns={[
              { header: "Fecha",// eslint-disable-next-line react/display-name
                code: (pagos) => <LabelFecha>{pagos.fecha}</LabelFecha>,},
              {
                header: "Método",
                // eslint-disable-next-line react/display-name
                accessor:"metodo",
              },
              {
              header: "monto",
              // eslint-disable-next-line react/display-name
              code: (pagos) => <Labelmoney>{pagos.monto}</Labelmoney>,
              }
            ]}
            
            data={VentaAPagar?.pagos||null}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalinfo.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalinfo.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <Container lg="px-20">
        <Pendientes  caja={true} statePendientes={statePendientes} getPendiente={venta=>{
                setVentaDirecta(venta);
              }}/>
          <div className="size-2 gap-2 py-1 ">
            <div className="size-form w-full">
              {oldRuta=="venta"?null:<InputClientVenta />}
              <DatosClienteVenta venta={true} />
            </div>
            <div className={(oldRuta=="venta"?"hidden":"size-2 w-full items-center")}>
              <InputVenta ventaex={ventaDirecta} />
              <button 
              className="btn-green1 w-full md:w-44 mx-1 "
              title="F9"
              onClick={e=>{
                setStatePendientes(!statePendientes);
              }}>
                Ventas Pendientes
              </button>
            </div>
          </div>
          <table className="bg-gradient-to-b from-bluei via-bluei to-blue-900 h-full border-2 shadow-lg border-gray-300">
            <thead className="top-0 rounded text-white text-sm align-middle cursor-pointer uppercase">
              <tr className="text-2xl ">
                <th key={0} className={"py-1 break-words"}>Forma Pago</th>
                <th key={1} className={"py-1 break-words"} >Cuenta</th>
                <th key={2} className={"py-1 break-words"}>Monto</th>
              </tr>
            </thead>
            <tbody>
             {pagos.map((item, index) => (
              <Pago
              key={index}
              config={{
                setdata: setpagos,
                deletepago: deletePago,
                data: pagos,
                funcionPagar: sendDatapagar,
                index: index,
                formasPago: () => {
                  let todasFormasPago = new Set([
                    ...FormasP?.opciones.SS,
                  ]);
                  let formaspagousadas = new Set();
                  for (let i = 0; i < pagos.length; i++) {
                    if (i !== index) {
                      formaspagousadas.add(pagos[i].metodo);
                    }
                  }

                  return todasFormasPago.difference(formaspagousadas);
                },
                cuentas: Sucursal('cuentas'),
                importeTotal:VentaAPagar.total,
              }}
           /> 
              ))}
              <tr>
                <td></td>
                <td className="flex flex-wrap justify-center">
                  <button
                    className="btn-dark w-1/4 bg-teal-500 break-words"
                    type="button"
                    onClick={addpay}
                    disabled={
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.monto > 0,
                        true
                      ) ||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.metodo !== "",
                        true
                      )||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.cuenta !== "",
                        true
                      )
                    }
                    style={{ transition: "all .15s ease" }}
                  >
                    +
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot className=" text-white font-semibold">
            <tr>
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">IMPORTE TOTAL:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney className="w-44" twoDecimal={true}>{VentaAPagar.total}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">PAGADO:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney className="w-44" twoDecimal={true}>{moneyToValue(pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0))}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">RESTANTE:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney className="w-44" twoDecimal={true}>{(porPagar>=0)?porPagar:0}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">CAMBIO:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney className="w-44" twoDecimal={true}>{(porPagar<=0)?(porPagar*-1):0}</Labelmoney></td>
              </tr>
            </tfoot>
          </table>

          <div className="container lg:px-5 lg:py-2 mx-auto w-full ">
            <div className=" flex flex-auto md:flex-row flex-col w-3/4 mx-auto">
              <motion.button
              whileHover={btnScale8}
              whileTap={btnTap}
              className="btn-green1 w-full"
              onClick={(e) => {
                setmodalTablaConfig({ isOpen: true, pagos:VentaAPagar.pagos});
              }}>
                Ver pagos
                </motion.button>
              <motion.button
              whileHover={btnScale8}
              whileTap={btnTap}
              className="btn-gray2 w-full md:mx-2"
              type="button"
              onClick={(e) => {
                if(e.detail!==1){return;}
                borrarDatos();
                if(["venta"].includes(oldRuta)){delRuta();}
              }}>
                Cancelar
              </motion.button>
              {validaPagos()?
               <motion.button
               whileHover={btnScale8}
               whileTap={btnTap}
                className={(porPagar<=0?"btn-green w-full":"btn-dark bg-yellow-500 w-full")}
                type="button"
                onClick={(e) => {
                  if(e.detail!==1){return;}
                  if(disableBtnCobrar){return;}
                  sendDatapagar(e);
                }}
              >
                {porPagar>0?(VentaAPagar?.status=="CREDITO#"+getSucursal()?"Abono":"Credito"):"Pagar"}
              </motion.button>
              :"" }
              
              <motion.button
               whileHover={btnScale8}
               whileTap={btnTap}
              className="btn-blue w-full md:mx-2"
              type="button"
              onClick={(e) => {
                if(e.detail!==1){return;}
                setcobranza(!cobranza);
              }}>
                Ver Cobranza
              </motion.button>
            </div>
          </div>

          {cobranza ? (
            <>
            <div className="h-full">
              <TablaCredito
                onclickRow={(item,e) => {
                  if(e.detail!==2){return}
                    setpagos([{ monto: 0.0,metodo: "", cuenta: "" }]);

                    SetVentaAPagar({
                      id: item.id,
                      sucursal: item.sucursal,
                      total: item.total - item.pagado,
                      pagos: null,
                      pagado: item.pagado,
                      recibido:0,
                      cambio:false,
                      restante:0,
                      importe: item.total,
                      status: item.statusTemp
                    });
                }}
              />
            </div>
             
                <div className="flex flex-row md:text-left text-center w-full">
                    <div className=" text-center text-medium font-bold uppercase w-full">
                      Credito disponible:
                      <Labelmoney twoDecimal={true} className="w-24 font-bold pl-2">{Clientes.creditoRestante}</Labelmoney>
                    </div>
                    <div className=" text-center text-medium font-bold uppercase w-full">
                      Por cobrar:{" "}
                      <Labelmoney twoDecimal={true} className="w-24 font-bold pl-2">{Clientes.creditoUsado}</Labelmoney>
                    </div>
                </div>
            </>
          ) : null}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  VentaAPagar: state.VentaAPagar,
  Clientes: state.ClientesVenta,
  FormasP: state.Catalogos.FORMASPAGO,
  Ruta: state.Ruta,
});

const mapDispatchToProps = (dispatch) => ({
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  delRuta:()=> dispatch(returnRuta()),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  SetVentaAPagar: (datos) =>
    dispatch({
      type: "SET_VENTAAPAGAR",
      ventaapagar: datos,
    }),
  VentasCredito: (datos) =>
    dispatch({
      type: "SET_VENTACREDITO",
      ventascredito: datos,
    }),
  SetTicket: (datos) =>
    dispatch({
      type: "SET_TICKET",
      ticket: datos,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Caja);
