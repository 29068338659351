import React from 'react'
import Labelmoney from '../labelmoney';

const BodyTicketCopia=({Ticket, textSize,Descripcion,copia })=> {
  const textsize = textSize || "";

  return ( <>
    <table className={(copia?"w-auto":"w-full")+" text-2xs font-semibold mt-1 "+textsize}>
      <thead>
        <tr>
          <th className="text-center">Cant</th>
          <th className="text-left">Articulo</th>
          <th className="text-center w-16">Precio</th>
          <th className="text-center w-10">Desc</th>
          <th className="text-center w-16">Subtotal</th>
        </tr>
      </thead>
      <tbody  className=''>
        {Ticket?.items?Ticket.items.map((item, index) => {
          return (
            <tr key={index} >
              <td className=" text-center pt-1">{item.cantidadVendida}</td>
              <td className=" text-start text-3xs pt-1"><p >{(item.id+" ":"")+(Descripcion?item.descripcion:item.descripcion.substring(0,35))}</p></td>
              <td className=" text-right pt-1"><Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney></td>
              <td className=" text-right pt-1">{item.descuento + "%"}</td>
              <td className=" text-right pt-1"><Labelmoney twoDecimal={true}>{item.importe}</Labelmoney></td>
            </tr>)
          })
          : null}
      </tbody>
      <tfoot>
        <tr>
          <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    </>
  )
}


export default BodyTicketCopia
