import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modalinfo from "./modal/info";
import {useGetVentaAsync, useGetBalanceClienteAsync} from "../Hooks/useApis";
import {CalculaPagos, getSucursal} from "../services/functions";
import LoadingModal from "./modal/LoadingModal";

const InputVenta = ({ addClient, SetVentaAPagar, dark,ventaex }) => {
  const input = useRef();
  
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({});

  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };

  function closeModalinfo(e) {  setmodalinfoConfig({ isOpen: false }); }

  const funcionBuscar = async(e) => {
    const tk = input.current.value.toUpperCase().trim();
    if (!tk) {return; }
    try{
      openmodalLoading("Cargando Ticket");
      const respVenta= await useGetVentaAsync(tk,"PENDIENTE");
      const ticket = respVenta.data;

      if (!ticket?.venta) {
        setmodalinfoConfig({
          isOpen: true,
          message: "No existe el ticket '" + input.current.value.toUpperCase() + "'",
        });
        return;
      }
      
      const respBalance = await useGetBalanceClienteAsync(ticket.venta.cliente);
      const balance = respBalance.data;
  
      if(balance.cliente.sucursal===getSucursal()){
        if (balance.message) {
          setmodalinfoConfig({
            isOpen: true,
            message: balance.message,
          });
          addClient({});
        }
      
        const creditoUsado = calcularCreditoUsado(balance.ventasCredito);
        const creditoRestante = balance.cliente.limiteCredito - creditoUsado;
      
        let nuevoCliente = { ...balance.cliente };
        nuevoCliente.creditoUsado = creditoUsado;
        nuevoCliente.creditoRestante = creditoRestante;
      
        addClient(nuevoCliente);
    
      }else{
        handleError("El ticket no pertenece a la Sucursal Actual");
        return;
      }


      /*addClient({
        id: ticket.venta.cliente,
        nombre: ticket.venta.nombreCliente,
        sucursal: ticket.venta.sucursal,
        type: "CLIENT",
        cotizado: true,
      });*/
      
      let pagado = CalculaPagos(ticket.venta?.pagos||[]);
      SetVentaAPagar({
        id: ticket.venta.id,
        sucursal: ticket.venta.sucursal,
        total: ticket.venta.total - pagado,
        pagos: null,
        pagado: pagado,
        importe: ticket.venta.total,
        recibido:0,
        cambio:false,
        restante:0,
        status: ticket.venta.statusTemp
      });
  
      input.current.value = "";
      closemodalLoading();
      //addItem(venta.items);
    }catch (error){
      handleError(error);
    }
  };


  function calcularCreditoUsado(cuentasACredito) {
    let creditoUsado = 0;
    for (let cuenta of cuentasACredito) {
      creditoUsado = creditoUsado + (cuenta.total - cuenta.pagado);
    }
    return creditoUsado;
  }
  
  const handleError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message || error,
    });
  };

  useEffect(()=>{
    if(!ventaex){return;}
    input.current.value = ventaex.shortId;
    funcionBuscar();
  },[ventaex])

  return (
    <>
      <LoadingModal config={modalLoading}  >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <form className="size-form items-center gap-1"
      onSubmit={(e) => {
        e.preventDefault();
        funcionBuscar();
      }}>
            <input
              type="text"
              className="border-form uppercase"
              name="articulo"
              placeholder="Ticket"
              style={{ transition: "all .15s ease" }}
              ref={input}
            />

            <button
              className={dark?"btn-blue1":"btn-gray2"}
              type="submit"
              style={{ transition: "all .15s ease" }}
            >
              Buscar
            </button>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) =>
    dispatch({
      type: "SET_ITEMSDATA",
      items: item,
    }),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  SetVentaAPagar: (datos) =>
    dispatch({
      type: "SET_VENTAAPAGAR",
      ventaapagar: datos,
    }),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
});


export default connect(mapStateToProps, mapDispatchToProps)(InputVenta);