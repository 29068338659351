import React, { useEffect } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import { Sucursal } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import BodyTicketPes from "./BodyTicketPes.js";

const TicketCopiaPes= ({ Ticket, delRuta }) => {

  useEffect(() => { window.print();}, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            <label className="text-base mt-3 justify-self-end font-black uppercase">COPIA</label>
            <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta();}}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <BodyTicketPes Ticket={Ticket}/>
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketCopiaPes);
