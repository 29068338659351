import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { setRutaRep } from '../../../redux/Actions';
import { FechaLocalToUTC, GetFechaActual, TransformIndex, getArrayAsync, getSucursal, getTotal, tipoCelda } from '../../../services/functions';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from '../Cash/TableReactWindows';
import Modaldanger from '../../modal/danger';

const ReporteArOther=({PropsReportes,addDatos,setHeader,setRutaRep,Datos}) => {
  const fechaI = FechaLocalToUTC("2023-01-01"),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
  const nameComponent = "arotherBalance",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const new_subject = { balanceBefore:0,balance:0, prestado:0,pagado:0};
  const datosComponent = Datos[nameComponent]||{};
  const todayStart=FechaLocalToUTC(GetFechaActual().fecha),todayEnd=FechaLocalToUTC(GetFechaActual().fecha,23,59,59);
  const allRegistros = useRef([]);

  const peticiones=[{
    hashKey:{valor:("AROTHOUT#"+getSucursal()),nombre:"id"},
    sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    
  },{
    hashKey:{valor:("AROTHIN#"+getSucursal()),nombre:"id"},
    sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
  },{ 
    indexName:"reverseIndex",
    hashKey:{valor:"USER",nombre:"type"},
    filters:[{nombre:"activo",valor:true,operador:"eq"},{nombre:"sucursal",valor:getSucursal(),operador:"eq"}] 
  },]

  const [modalLoading, setmodalLoading] = useState({}),
  [balance,setBalance]=useState([]),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});


  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };
  const closeModaldangererror=(e)=> {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  useEffect(()=>{
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}

    const getBalanceArOther=async()=>{
      openmodalLoading();
      try{
        const reporte={},allItems={};
        let registros =[];

        const users = await getArrayAsync(peticiones[2],[]);
        const arothouts = await getArrayAsync(peticiones[0],[]);
        const arothins = await getArrayAsync(peticiones[1],[]);

        arothouts.forEach(arothout=>{
          const subject = TransformIndex(arothout.contracuenta);
          if(!reporte[subject]){ reporte[subject] = {...new_subject,subject:subject,nombre:subject}}
          if(!allItems[subject]){ allItems[subject]={subject:subject,items:[{...arothout}],nombre:subject}}
          else{allItems[subject].items.push({...arothout});}
          if(arothout.type<PropsReportes.fecha_Inicial){
            reporte[subject].balanceBefore += arothout.importe*-1;
          }else if(arothout.type>=PropsReportes.fecha_Inicial&&arothout.type<=fechaF){
            reporte[subject].prestado+=arothout.importe*-1;
          }
        })

        arothins.forEach(arothin=>{
          const subject = TransformIndex(arothin.contracuenta);
          if(!reporte[subject]){ reporte[subject] = {...new_subject,subject:subject,nombre:subject}}
          if(!allItems[subject]){ allItems[subject]={subject:subject,items:[{...arothin}],nombre:subject}}
          else{allItems[subject].items.push({...arothin});}
          if(arothin.type<PropsReportes.fecha_Inicial){
            reporte[subject].balanceBefore -= arothin.importe;
          }else if(arothin.type>=PropsReportes.fecha_Inicial&&arothin.type<=PropsReportes.fecha_Final){
            reporte[subject].pagado+=arothin.importe;
          }
        })

        users.forEach(user=>{
          const subject = user.id;
          if(!reporte[subject]){ reporte[subject] = {...new_subject,subject:subject,nombre:user.nombre}}
          else{ reporte[subject].nombre = user.nombre}
          if(!allItems[subject]){ allItems[subject]={subject:subject,items:[],nombre:user.nombre}}
          else{allItems[subject].nombre = user.nombre;}
        })


        Object.keys(reporte).forEach(clave=>{
          registros.push({...reporte[clave],balance:reporte[clave].balanceBefore+reporte[clave].prestado-reporte[clave].pagado});
        })

        closemodalLoading();
        setBalance(registros)
        allRegistros.current = allItems;
        addDatos(nameComponent,[registros,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,allRep:allItems}]);     
      }catch (error){
        closemodalLoading();
      }
    }


    if(JSON.stringify(datosComponent)=='{}'){
      getBalanceArOther();
    
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1].fechai&&PropsReportes.fecha_Final==datosComponent[1].fechaf){
        setBalance(datosComponent[0]);
        allRegistros.current = datosComponent[1].allRep
        return;
      }
      getBalanceArOther();
    }
    
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);


  return (
    <>
     <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          console.log(datos)
          /*if(!balance?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const data = datos||balance,
          nameFile = "BalanceAP",
          headerFile = "Reporte balance de proveedores del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={
            'Proveedor':13,
            'Beg Balance':17,
            'Comprado':17,
            'Envio':17,
            'Devolucion':17,
            'Pagado':17,
            'Descuento':17,
            'End Balance':17,
            'Nombre':40
          };
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((ap,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(ap.proveedor);
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(valueToMoney2(ap?.balanceBefore||0));
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(ap?.comprado||0));
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(ap?.envio||0));
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(ap?.devolucion||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(ap?.pagado||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(ap?.descuento||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(ap?.balance||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(ap.nombre);
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[1]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+sizeData).formula(""+columnasXlsx[1]+sizeData+"+"+columnasXlsx[2]+sizeData+"+"+columnasXlsx[3]+sizeData+"-"+columnasXlsx[4]+sizeData+"-"+columnasXlsx[5]+sizeData+"-"+columnasXlsx[6]+sizeData);
          sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);
      

          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
          */
        }}
        columns={[
          {
            key:"subject",
            header:"Minor Account",
            width:150, 
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
            onClick: ({e,row})=>{
             if(e.detail==2){
                const oldDatos = Datos['arotherDetail'] || [];
                addDatos(
                  "arotherDetail",
                  [
                    (oldDatos?.[1]?.subject==row.subject?oldDatos[0]:null),
                    {
                      fechai:(oldDatos?.[1]?.fechai || PropsReportes.fecha_Inicial),
                      fechaf:(oldDatos?.[1]?.fechaf || PropsReportes.fecha_Final),
                      balance:row,
                      subject:row.subject,
                      allRep:allRegistros.current[row.subject].items
                    }
                  ]
                )
                setHeader({text:row.subject+" Detail ",subject:row.subject})
                setRutaRep("arotherDetail");
              }
            }
          },
          {
            key:"balanceBefore",
            header: "Forwarded",
            width:120,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"prestado",
            header: "Loanet out",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"pagado",
            header: "Collected",
            width:100,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header: "Balance",
            width:130,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"nombre",
            header: "Name",
            width:250,
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
          }
        ]}
        data={balance}
      />
    </>
  )
}

  const mapDispatchToProps = (dispatch) => ({
    setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
    setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
    addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  });
  const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
    Datos: state.Datos
  });

export default connect (mapStateToProps,mapDispatchToProps)(ReporteArOther)