import React, { useEffect } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import { showMoney, Sucursal, TransformSys, FechaUTCToLocal, GetFechaActual } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import Labelmoney from "../labelmoney";
import LabelFecha from "../LabelFecha.jsx";
import HeaderTicket from "./HeaderTicket.jsx";
import FooterTicket from "./FooterTicket.jsx";

const TicketPagadoAbonos = ({ Ticket, delRuta }) => {
  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
          <label className="text-base mt-3 justify-self-end font-black uppercase">ABONO</label>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta(); }}
            className={ "w-[90px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <HeaderTicket Ticket={Ticket} copia={true} />
          <FooterTicket Ticket={Ticket}/>
          {Sucursal('ticket')?.productos.length?
            <div className="w-full text-center mt-3">
              <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
              {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
            </div>
          :""}
          {Sucursal('ticket')?.footer?
            <p className="w-full text-center mt-3">{Sucursal('ticket').footer}</p>
          :""}
        </div>
      </div>
                {/* <div className="flex flex-col w-full my-2">
            <div className="grid grid-cols-3 w-full">
              <div className="font-bold text-start">Metodo</div>
              <div className="font-bold text-start">Monto</div>
              <div className="font-bold text-start">Fecha</div>
            </div>
            {Ticket?.pagos.length?
              <div className="w-full">
                {Ticket.pagos.map((pago,index)=>(
                  <div key={index} className="grid grid-cols-3">
                    <label>{pago.metodo}:</label>
                    <Labelmoney className={"w-20"} twoDecimal={true}>{(pago?.excedente?pago.excedente:(pago.monto || 0))}</Labelmoney>
                    <LabelFecha>{pago.fecha}</LabelFecha>
                  </div>
                ))}
              </div>
            :""}
            <div className={"grid grid-cols-3 w-full"}>
              <span></span><span></span><span>-</span>
              <label>Importe:</label><Labelmoney className={"w-20"} twoDecimal={true}>{Ticket?.total}</Labelmoney><span></span>
              <label className="">Pagado:</label><Labelmoney className="w-20 " twoDecimal={true}>{(Ticket?.recibido?Ticket.recibido:Ticket?.pagado)}</Labelmoney><span></span>
            </div>
            {Ticket?.total-Ticket?.pagado>0?
              <div className="grid grid-cols-3">
                <label>Restante:</label><Labelmoney className="w-20" twoDecimal={true}>{(Ticket?.total-Ticket?.pagado || 0)}</Labelmoney><span></span>
              </div>
            :""}
          </div> */}
    </>
    
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPagadoAbonos);
