import React, { useState,useEffect,useRef } from "react";
import { connect } from "react-redux";
import { FechaLocalToUTC, getArrayAsync, getSucursal, getTotal, tipoCelda, valueToMoney } from "../../../services/functions.js";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import Modalgeneric from "../../modal/generic.js";
import TableModal from "../../modal/TableModal.jsx";
import InputPrecioPanel from "../../InputPrecioPanel.jsx";
import TableReactWindows from "../Cash/TableReactWindows.jsx";

const ReporteAjuste = ({PropsReportes,setHeader}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalItems, setModalItems] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [itemSelect,setItemSelect]=useState([]),
  [servicios,setServicios]=useState([]);
  const nameComponent = "inventarioMerma",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const title = useRef("Sin Datos");


  const funcionError = (error) => {
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message,});
  };

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  function closeModalItems(e) {
    setModalItems({ isOpen: false });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };


  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
     setHeader({text:"Reporte de Garantias"})
  },[PropsReportes.rutas]);

  useEffect(async()=>{
    openmodalLoading();
    let peticion ={
      hashKey:{valor:("MERMA#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[FechaLocalToUTC(PropsReportes.fecha_Inicial),FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59)],operador:"between",nombre:"type"}
    }
    try{
      let newMermas= [];
      const resp = await getArrayAsync(peticion,[]);
      closemodalLoading();

      resp.forEach(merma=>{
        newMermas.push({...merma,in:valueToMoney(merma.importeTotal>0?merma.importeTotal:0),out:valueToMoney(merma.importeTotal<0?merma.importeTotal:0)})
      })
      setServicios(newMermas)
    }catch (error){
      closemodalLoading();
      funcionError(error)
    }
  },[])

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Servicios</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
    
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric w="size-w-80" h="size-h-80" tab="300" config={modalItems} closeModal={closeModalItems}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Garantias</Modalgeneric.Title>
          <TableReactWindows
            columns={[
              { header: "Clave", width:150, key: "id", type:tipoCelda.text,filter:true,title:"Id del producto" },
                { header: "Descripción", width:450,  key: "descripcion", type: tipoCelda.text },
                { header: "linea", width:150,  key: "linea", type: tipoCelda.text,filter:true  },
                { header: "Exist Ant", width:80, key:"existenciaOld", type: tipoCelda.number,filter:true, title:"Existencia anterior al ajuste" },
                { header: "Responsable", width:150,  key: "responsable", type: tipoCelda.text,filter:true },
                { header: "Cant", width:90, key: "cantidad", type: tipoCelda.number ,filter:true,footer:true,function: getTotal, title:"Cantidad Ajustada"},
                { header: "Costo U.", width:120, key:"unitCost", type:tipoCelda.money,filter:true, title:"Costo del producto" },
                { header: "Importe", width:120, key: "importe", type: tipoCelda.money,filter:true,footer:true,function: getTotal },
            ]}
            data={itemSelect}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalItems}
            className="btn-gray2"
            name="modalgeneric"
            tabIndex="300"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div className="flex w-full justify-center items-center h-full">
          <TableReactWindows
            columns={[
              {
                key:"type",
                header: "Fecha",
                width:90,
                type:tipoCelda.date,
                filter:true,
              },
              {
                key:"descripcion",
                header: "Description",
                width:350,
                type:tipoCelda.text,
                filter:true
              },
              {
                key:"personal",
                header: "Personal",
                width:120,
                type:tipoCelda.text,
                className:"text-center",
                filter:true
              },
              {
                key:"in",
                header: "IN",
                width:100,
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function:getTotal
              },
              {
                key:"out",
                header: "OUT",
                width:100,
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function:getTotal
              },
              {
                key:"items",
                header:"Detail",
                width:80,
                 // eslint-disable-next-line react/display-name
                content:({row,index})=>{
                  return <div className="flex w-full items-center justify-center">
                    <button 
                    onClick={e=>{
                      let newItems = [];
                      row.items.forEach(item=>{
                        newItems.push({...item,unitCost:item?.importe/item?.cantidad})
                      })
                      setItemSelect(newItems)
                      setModalItems({ isOpen: true, message: "",});
                    }}>
                    <i className=" text-sky-600 hover:text-sky-400 transform hover:scale-125 fas fa-list"></i>
                    </button>
                  </div>
                }
              }
            ]}
            data={servicios}
          />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPropsInventario: (estado) => dispatch({type:"SET_PROPSINVENTARIO", estado:estado}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});

const mapStateToProps = (state) => ({
  HeaderCompra: state.HeaderCompra,
  propsInventario: state.PropsInventario,
  PropsReportes: state.PropsReportes,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteAjuste);
