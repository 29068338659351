
import { AddHoras, apiUse, apiUsePromise, encryptData, getIdUser, getNombre, getSucursal, horasValidacion, Sucursal } from "../services/functions"

export const useCatalogos = (funcionExito,funcionError)=>{
        apiUse({
            url: "/get-catalog",
            method: "POST",
            customdata: { id: "CATALOGOS",},
            handleSuccessful: (respuesta)=>{funcionExito(respuesta.catalogos)},
            handleError: (error)=>{funcionError(error.message)},
        });
}

export const useCatalogo = async (catalogo,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-catalog",
        method: "POST",
        customdata: { id: catalogo,},
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    });
}

export const useGetProduct = ({producto,sucursal,linea,sinLimite,lastKeys,funcionExito,funcionError})=>{
    const newsucursal = (sucursal==""?sucursal:getSucursal());
    const peticion = {id: producto.toUpperCase() };
    if(linea){peticion.linea = true}
    if(sinLimite){peticion.sinLimite = true;}
    if(lastKeys){peticion.LastEvaluatedKey=lastKeys;}
    else{
        peticion.sucursal= newsucursal;
    }
    apiUse({
        url: "/get-product",
        method: "POST",
        data: peticion,
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    }); //devuelve productos y linea
}

export const useGetProductAsync = ({producto,sucursal,linea,sinLimite,lastKeys})=>{
    const newsucursal = (sucursal==""?sucursal:getSucursal());
    const peticion = {id: producto.toUpperCase() };
    if(linea){peticion.linea = true}
    if(sinLimite){peticion.sinLimite = true;}
    if(lastKeys){peticion.LastEvaluatedKey=lastKeys;}
    else{
        peticion.sucursal= newsucursal;
    }
    return apiUsePromise({
        url: "/get-product",
        method: "POST",
        data: peticion,
    });
}

export const useUpdateProducto = (items,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-product",
        method: "POST",
        data: {
            sucursal: getSucursal(),
            action : "add",
            items: items
          },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    }); //devuelve productos y linea
}

/*export const useUpdateProductoId = (items,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-product",
        method: "POST",
        data: {
            action : "add",
            items: items
          },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    }); //devuelve productos y linea
}*/


export const useCustomQuery = (peticion,funcionExito,funcionError)=>{
    apiUse({
        url: "/custom-query",
        method: "POST",
        data: {
            indexName: peticion?.indexName,
            hashKey: peticion?.hashKey,
            sortKey: peticion?.sortKey ||{},
            filters: peticion?.filters || []
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta);},
        handleError: (error)=>{funcionError(error)},
    }); 
}

export const useGetSomething=(type,optionFilter,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-something",
        method: "POST",
        data: {
            type : type,
            optionalMainFilter: optionFilter
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    });
}

export const useSetLogin = (encodedData,funcionExito,funcionError)=>{
    apiUse({
        url: "/login",
        method: "POST",
        data: {},
        headers: { Authorization: "Bearer " + encodedData },
        login: true,
        handleSuccessful: (respuesta)=>{ 
            respuesta.sucursalOrigen = respuesta.sucursal;
            respuesta.validacion = AddHoras(new Date(),horasValidacion)
            localStorage.setItem("usuario", JSON.stringify(respuesta));
            funcionExito(respuesta);
        },
        handleError: (error)=>{funcionError(error)},
      });
}

export const useSetSucursal = (sucursal,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-sucursal",
        method: "POST",
        data: { id: sucursal },
        headers: { Authorization: "Bearer " },
        handleSuccessful: (dataGetSucursal) => {
            const login = JSON.parse(localStorage.getItem("usuario"));
            dataGetSucursal.categorias.push({FACTURA:0});
            localStorage.setItem("usuario", JSON.stringify({...login,sucursal:dataGetSucursal.id}));
            localStorage.setItem("sucursal", JSON.stringify(dataGetSucursal));
            funcionExito(dataGetSucursal);
        },
        handleError: (error) => {funcionError(error);},
      });
}

export const useUpdateConfig=(peticion,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-configs",
        method: "POST",
        data: peticion,
        handleSuccessful: (dataGetSucursal) => {funcionExito(dataGetSucursal); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useGetSucursal = (sucursal,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-sucursal",
        method: "POST",
        data: { id: sucursal },
        handleSuccessful: (dataGetSucursal) => {funcionExito(dataGetSucursal); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useGetClient = (cliente,funcionExito,funcionError)=>{
    let newclient = (cliente=="SYS"?cliente+"#"+getSucursal():cliente);
    apiUse({
        url: "/get-client",
        method: "POST",
        data: { id: newclient.toUpperCase(), sucursal:getSucursal() },
        handleSuccessful: (respuesta)=>{
            if(respuesta.length==1){ respuesta[0].id = (respuesta[0].id=="SYS#"+getSucursal()?"SYS":respuesta[0].id) }
            funcionExito(respuesta)
        },
        handleError: (error)=>{funcionError(error.message)},
    }); //devuelve array de clientes
}

export const useGetClientAsync = ({cliente,lastKeys})=>{
    const data ={ id: (cliente=="SYS"?cliente+"#"+getSucursal():cliente), sucursal: getSucursal() };
    if(lastKeys){
        data.LastEvaluatedKey = lastKeys
    }
    return apiUsePromise({
        url: "/get-client",
        method: "POST",
        data: data,
    });
}

export const useGetBalanceCliente= (cliente,funcionExito,funcionError)=>{
    let newclient = (cliente=="SYS"?cliente+"#"+getSucursal():cliente);
    apiUse({
        url: "/get-balance-cliente",
        method: "POST",
        data: { cliente: newclient.toUpperCase(), sucursal: getSucursal() },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    }); //devuelve array de clientes
}

export const useGetPersonal=(personal,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-personal",
        method: "POST",
        data: { id: personal, sucursal: getSucursal() },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error.message)},
    });
}

export const useGetBalanceProveedor = (proveedor,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-balance-proveedor",
        method: "POST",
        data: {
            id:proveedor,
            sucursal:getSucursal()
        },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useGetProveedor =(idproveedor,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-proveedor",
        method: "POST",
        data: {
            id:idproveedor,
            sucursal:getSucursal()
        },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useGetCompra = (folio,funcionExito,funcionError,lastKeys)=>{
    const data ={ id: folio, sucursal: getSucursal() };
    if(lastKeys){
        data.LastEvaluatedKey = lastKeys
    }
    apiUse({
        url: "/get-compra",
        method: "POST",
        data: data,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}
export const useGetCompraAsync = ({folio,lastKeys})=>{
    const data ={ id: folio, sucursal: getSucursal() };
    if(lastKeys){
        data.LastEvaluatedKey = lastKeys
    }

    return apiUsePromise({
        url: "/get-compra",
        method: "POST",
        data: data,
    });
}


export const useInsertCompra = (compra,funcionExito,funcionError)=>{
    compra.timezone = Sucursal("timezone");
    apiUse({
        url: "/insert-compra",
        method: "POST",
        data: compra,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}


export const useUpdateCompra = (compra,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-compra",
        method: "POST",
        data: compra,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useDeleteCompra = (venta,funcionExito,funcionError)=>{
    apiUse({
        url: "/delete-compra",
        method: "POST",
        data: { id: venta.toUpperCase().trim(), sucursal: getSucursal(),personal: getIdUser(),timezone : Sucursal("timezone") },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useDeleteTraspaso = (venta,funcionExito,funcionError)=>{
    apiUse({
        url: "/delete-traspaso",
        method: "POST",
        data: { id: venta.toUpperCase().trim(), sucursal: getSucursal(),timezone : Sucursal("timezone") },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useGetCotizacion = (cotizacion,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-cotizacion",
        method: "POST",
        data: { id: cotizacion.trim(), sucursal: getSucursal() },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta.data)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useInsertCotizacion = (cotizacion,funcionExito,funcionError)=>{
    cotizacion.cliente = (cotizacion.cliente=="SYS"?cotizacion.cliente+"#"+getSucursal():cotizacion.cliente);
    apiUse({
        url: "/insert-cotizacion",
        method: "POST",
        data: cotizacion,
        handleSuccessful: (respuesta)=>{
            if(respuesta?.error){ throw new Error(respuesta?.error);}
            funcionExito(respuesta)
        },
        handleError: (error)=>{funcionError(error)},
    }); //
}

export const useDeleteCotizacion = (cotizacion,funcionExito,funcionError)=>{
    apiUse({
        url: "/delete-cotizacion",
        method: "POST",
        data: { id: cotizacion.toUpperCase().trim(), sucursal: getSucursal() },
        handleSuccessful: (respuesta)=>{
            if(respuesta?.errors){ throw new Error(respuesta?.errors);}
            funcionExito(respuesta.cotizacion)
        },
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useGetVenta = (idventa,status,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-venta",
        method: "POST",
        data: {
            id: idventa,
            status: status,
            sucursal: getSucursal(),
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //
}

export const useInsertVenta = (venta,funcionExito,funcionError)=>{
    venta.cliente = (venta.cliente=="SYS"?venta.cliente+"#"+getSucursal():venta.cliente);
    venta.timezone = Sucursal("timezone");
    apiUse({
        url: "/insert-venta",
        method: "POST",
        data: venta,
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //de
}

export const useUpdateVenta = (idventa,pagos,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-venta",
        method: "POST",
        data: {
            id:idventa,
            pagos:pagos,
            sucursal:getSucursal()},
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //de
}

export const useUpdateVentaAsync = (idventa,pagos)=>{
    return apiUsePromise({
        url: "/update-venta",
        method: "POST",
        data: {
            id:idventa,
            pagos:pagos,
            sucursal:getSucursal()
        }
    });
}

export const useDeleteVenta = (venta,funcionExito,funcionError)=>{
    apiUse({
        url: "/delete-venta",
        method: "POST",
        data: { id: venta.toUpperCase().trim(), sucursal: getSucursal(),timezone : Sucursal("timezone"),type:"VENTA", personal: getIdUser() },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta.venta)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useVentaPerdida = (item,cantidad,funcionExito,funcionError)=>{
    apiUse({
        url: "/insert-venta-perdida",
        method: "POST",
        data: { 
            item: item, 
            cantidad: cantidad,
            sucursal: getSucursal(),
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta.message)},
        handleError: (badError)=>{funcionError(badError)},
    });
}

export const useGetPermisoEspecial = (user,passw,permiso,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-permiso-especial",
        method: "POST",
        data: {
            id: user,
            sucursal: getSucursal(), // tomar este valor del localstorage o redux
            password: encryptData(passw),
            permiso: permiso,
          },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useGetPedidosEsp = (cliente,status,sucursales,opcion,funcionExito,funcionError)=>{
    let newclient = (cliente=="SYS"?cliente+"#"+getSucursal():cliente);
    apiUse({
        url: "/get-pedidos-especiales",
        method: "POST",
        data: {
            cliente: newclient,
            sucursales: sucursales, 
            status: status,
            opcion:opcion,
          },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta)},
        handleError: (error)=>{funcionError(error)},
    }); //devuelve 
}

export const useGetPes = (pedido)=>{
    return apiUsePromise({
        url: "/get-pedido-especial",
        method: "POST",
        data: {
            id: pedido,
            sucursal: getSucursal(),
          },
    });
}

export const useInsertVentaEspecial = (pedido,funcionExito,funcionError)=>{
    pedido.cliente = (pedido.cliente=="SYS"?pedido.cliente+"#"+getSucursal():pedido.cliente);
    apiUse({
        url: "/insert-pedido-especial",
        method: "POST",
        data: pedido,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useInsertCompraEspecial = (compra,funcionExito,funcionError)=>{
    apiUse({
        url: "/insert-compra-especial",
        method: "POST",
        data: compra,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useUpdateCompraEspecial = (compra,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-compra-especial",
        method: "POST",
        data: compra,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useGetInventarioCategoria =(funcionExito,funcionError)=>{
    apiUse({
        url:"/inventario",
        method:"POST",
        data : "",
        handleSuccessful: (respuesta)=>{funcionExito(respuesta);},
        handleError: (error)=>{funcionError(error);}
    })
}

export const useUpdateCatalog = (name,value,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-catalog",//SET PARA MAPAS, ADD PARA ARRAYS
        method: "POST",
        data: { names: name, value:value },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useUpdateCatalogos = (id,opciones,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-catalog",//SET PARA MAPAS, ADD PARA ARRAYS
        method: "POST",
        data: { id: id, opciones:opciones },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useInsertMerma=(props,funcionExito,funcionError)=>{
    apiUse({
        url: "/insert-merma",
        method: "POST",
        data: { sucursal:getSucursal(), descripcion:props.descripcion,personal:getIdUser(),tipo:props.tipo,importeTotal:props.importeTotal,cantidadTotal:props.cantidadTotal, items:props.items },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    })
}

export const useInsertMovimiento =(item,funcionExito,funcionError)=>{
    apiUse({
        url:"/insert-movimiento",
        method:"POST",
        data : {...item,
            sucursal:getSucursal(),
            personal:getIdUser(),
            nombrePersonal:getNombre(),
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta);},
        handleError: (error)=>{funcionError(error);}
    })
}

export const useGetMovimientos =(datos,funcionExito,funcionError)=>{
    apiUse({
        url:"/get-movimientos",
        method:"POST",
        data : {
            fechaInicial:datos.fechaInicial,
            fechaFinal:datos.fechaFinal,
            sucursales:datos.sucursales,
            clave:datos.clave,
            cuenta:(datos?.cuenta?datos.cuenta:"")
        },
        handleSuccessful: (respuesta)=>{funcionExito(respuesta);},
        handleError: (error)=>{funcionError(error);}
    })
}

export const useUpdateMovimiento = (item,funcionExito,funcionError)=>{
    const new_item = {...item,sucursal:getSucursal(),personal:getIdUser(), nombrePersonal:getNombre(),};
    apiUse({
        url: "/update-movimiento",//SET PARA MAPAS, ADD PARA ARRAYS
        method: "POST",
        data: new_item,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useDeleteMovimiento = (type,clave,concepto,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-movimiento",//SET PARA MAPAS, ADD PARA ARRAYS
        method: "POST",
        data: { id:type,sucursal:getSucursal(),importe:0,concepto:concepto,personal:"",nombrePersonal:"", clave:clave,metodo:"",descripcion:"",cuenta:"",contracuenta:"", },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useUpdateService = (items,funcionExito,funcionError)=>{
    apiUse({
        url: "/update-service",//SET PARA MAPAS, ADD PARA ARRAYS
        method: "POST",
        data: { sucursal: getSucursal(),  items: items },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useFactura = (factura)=>{
    return apiUsePromise({
        url: "/cfdi-create",
        method: "POST",
        data: factura,
    });
}


export const useCancelarFactura = (id,motivo,uuid,funcionExito,funcionError)=>{
    const data = {id:id,motivo:motivo};
    (uuid!=""?data.uuid = uuid:"");
    apiUse({
        url: "/cancelar-factura",
        method: "POST",
        data: data,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useGetFactura = (id,formato,funcionExito,funcionError)=>{
    apiUse({
        url: "/get-factura",
        method: "POST",
        data:{
            id:id,
            formato:formato
        },
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}

export const useInsertTraspaso = (traspaso,funcionExito,funcionError)=>{
    traspaso.timezone = Sucursal("timezone");
    apiUse({
        url: "/insert-traspaso",
        method: "POST",
        data: traspaso,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
    });
}


export const useGetTraspaso = (folio,funcionExito,funcionError,lastKeys)=>{
    const data ={ id: folio, sucursal: getSucursal() };
    if(lastKeys){
        data.LastEvaluatedKey = lastKeys
    }
    apiUse({
        url: "/get-traspaso",
        method: "POST",
        data: data,
        handleSuccessful: (respuesta) => {funcionExito(respuesta); },
        handleError: (error) => {funcionError(error);},
      });
}

export const useGetTraspasoAsync = ({folio,lastKeys})=>{
    const data ={ id: folio, sucursal: getSucursal() };
    if(lastKeys){
        data.LastEvaluatedKey = lastKeys
    }
    return apiUsePromise({
        url: "/get-traspaso",
        method: "POST",
        data: data,
    });
}


export const useGetVentaAsync = (idventa,status)=>{
    return apiUsePromise({
        url: "/get-venta",
        method: "POST",
        data: {
            id: idventa,
            status: status,
            sucursal: getSucursal()
        }
    }); //
}


export const useInsertMinorSubject = (data)=>{
    return apiUsePromise({
        url:"/insert-subjectminor",
        method:"POST",
        data:data
    })
}

export const useCatalogoAsync = (catalogo)=>{
    return apiUsePromise({
        url:"/get-catalog",
        method:"POST",
        customdata: { id: catalogo,},
    })
}

export const useGetBalanceClienteAsync= (cliente)=>{
    let newclient = (cliente=="SYS"?cliente+"#"+getSucursal():cliente);
    return apiUsePromise({
        url: "/get-balance-cliente",
        method: "POST",
        data: { cliente: newclient.toUpperCase(), sucursal: getSucursal() },
    }); //devuelve array de clientes
}