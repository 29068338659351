import React, { useEffect, useState } from 'react'
import Modalgeneric from './generic';
import ReporteVentasCreditoDay from '../Reportes/Ventas/ReporteVentasCreditoDay';
import { motion } from 'framer-motion';
import ReportePesEliminados from '../Reportes/Ventas/ReportePesEliminados';
import { btnScale8 } from '../../static/motionStyle';
import { User, havePermission } from '../../services/functions';
import ReporteProductosAltas from '../Reportes/Productos/ReporteProductosAltas';
import ReporteComprasEliminadas from '../Reportes/Compras/ReporteComprasEliminadas';
import ReporteVentasEliminadas from '../Reportes/Ventas/ReporteVentasEliminadas';
import ReporteCorteDia from '../Reportes/ReporteCorteDia';
import { ReporteVentaClienteLinea } from '../Reportes/ReporteVentaClienteLinea';

const ModalReportes=(props)=> {
  const closeModalReports = props.closeModal, 
  modalReports = props.modal,
  repsDefault = {
    ventasCreditoDay : {valor:"Ventas a Credito del dia",visible:false},
    pesEliminados : {valor:"PES Eliminados",visible:false},
    listaProductos : {valor:"Items",visible:false},
    comprasEliminados: {valor:'Compras Eliminadas',visible:false},
    ventasEliminadas: {valor:'Ventas Eliminadas',visible:false},
    corteDia: {valor:'Corte del dia',visible:false},
    ventasClientesLinea: {valor: 'Ventas x Cliente x Linea', visible:false},
  },
  rutas = {
    ventasCreditoDay : <ReporteVentasCreditoDay/>,
    pesEliminados: <ReportePesEliminados/>,
    listaProductos: <ReporteProductosAltas/>,
    comprasEliminados:<ReporteComprasEliminadas/>,
    ventasEliminadas:<ReporteVentasEliminadas/>,
    corteDia:<ReporteCorteDia/>,
    ventasClientesLinea: <ReporteVentaClienteLinea/>,
  };
  const [reportes,setReportes] = useState({...repsDefault}),
  [ruta,setRuta]=useState("");

  useEffect(()=>{
    const reps = {...reportes};
    if(!havePermission("CANCELAR VENTAS")){
      delete reps["pesEliminados"];
    }
    if(!havePermission("REPORTE VENTAS")&&!["ADMINISTRATIVO","GERENCIA","INFORMATICA"].includes(User('departamento'))){
      delete reps["listaProductos"]
    }
    setReportes(reps);
  },[])

  useEffect(() => {
    let newRuta = "";
    Object.keys(reportes).filter(key=>{
      if(reportes[key].visible==true){
        newRuta = key;
      }
    })
    setRuta(newRuta);
  }, [reportes])
  


  return <Modalgeneric  closeModal={closeModalReports} config={modalReports} front={"z-20"}  >
  <Modalgeneric.Body >
    <div className="relative size-form gap-2 h-full justify-center ">
      <motion.ul  className='w-60 border md:h-full border-gray-500 cursor-pointer rounded-lg font-semibold text-xs'>
        {Object.keys(reportes).map((key,index)=>{
          return <motion.li 
          className={" hover:bg-sky-100 flex items-center gap-1 "+(reportes[key].visible?"right-0 pl-2":"")}
          key={key} 
          whileHover={btnScale8}
          whileTap={{ scale: 0.95 }}
          onClick={e=>{
            if(e.detail==1){
              if(reportes[key].visible==true){
                const rep = {...reportes}
                rep[key].visible = false
                setReportes(rep);
              }
            }
            if(e.detail!==2){return}
            const newRep = {...reportes};
            Object.keys(newRep).forEach(ky=>{
              const item = {...newRep[ky]};
              if(key==ky){ item.visible = !newRep[ky].visible}
              else{item.visible = false}
              newRep[ky] = item
            })

            setReportes(newRep)
          }}>
            <i className={(reportes[key].visible?"fas fa-folder-open text-ambar-400 ":"fas fa-folder text-black-400")}/>
            {reportes[key].valor}
          </motion.li>
        })}
      </motion.ul>
      <div className=' w-full h-full relative overflow-y-auto'>
        {rutas[ruta]??""}
      </div>
      
    </div>
  </Modalgeneric.Body>
  <Modalgeneric.Buttons>
    <button
      type="button"
      className="btn-gray2"
      tabIndex="200"
      onClick={closeModalReports}
    >
      Cerrar
    </button>
  </Modalgeneric.Buttons>
  </Modalgeneric>
}

export default ModalReportes