import React, { useState } from "react";
import { connect } from "react-redux";
import { setRuta } from "../redux/Actions";
import { havePermission } from "../services/functions";
import { btnScale, btnScale105, btnScale8 } from "../static/motionStyle";
import { motion } from 'framer-motion';

const PanelCard = (props) => {
  const [permiso] = useState(havePermission(props.permiso));

  const navegar = (ruta) => {
    props.setRuta(ruta);
  };

  return (
    <>
      {permiso ? (
        <span className="w-full md:w-80 text-center ">
          <motion.div 
          whileHover={btnScale}
          transition={{ duration: 0.3}}
          className={"cursor-pointer relative hover:z-10 flex flex-col min-w-0 break-words hover:bg-gray-400 bg-white w-full shadow-lg rounded-lg group"}>
            {props.children}

            <div
              className="px-4 py-5 flex-auto"
              onClick={(e) => {
                navegar(props.url);
              }}
            >
              <div
               className={" p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-3 shadow-lg rounded-full  "}>
                <i className={"fas fa-circle text-6xl " +props.Logueo?.textcolor}> </i>
                <i className={props.icon+" absolute text-white"}></i>
              </div>
              {/* <div className={" p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-3 shadow-lg rounded-full bg-blue-800"}>
                  <i className={props.icon+" text-white"}></i>
              </div> */}
              <p className="text-2xl group-hover:text-white font-bold">
                {props.text}
              </p>
            </div>
          </motion.div>
        </span>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
});

const mapStateToProps = (state) => ({
  Logueo: state.Logueo,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelCard);
