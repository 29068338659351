import React, { useMemo, useState } from "react";
import { ConvertMoneytoFormat, valueToMoney, valueToMoney2 } from "../services/functions.js";

const LabelNumber = (props) => {
  const [aux,setaux]=useState({text:"",number:0});

  useMemo(()=>{
    const val = (props.children?(props.children||0).toString():"0")
    ConvertMoneytoFormat(val,aux,setaux);
  },[props.children]);

  return <p className={(props?.className?props.className:"w-full ")+" font-semibold outline-none bg-transparent text-right "+(aux.number<0?"text-red-500":props?.font)}>
     {aux.text}
    </p>;
};

export default LabelNumber;