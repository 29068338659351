import React, { useState, useEffect} from "react";
import { setCantidadEsp, setRuta} from "../../redux/Actions";
import { connect } from "react-redux";
import {  AddDiasFecha, apiUse, FechaLocalToUTC, FechaUTCToLocal, GetFecha, GetFechaActual, GetFechaActualUTC, GetFechaNoWeekEnds, getIdUser, GetItemsPes, getNombre, getSucursal, getTotal, havePermission, moneyToValue, RestaFechas, tipoCelda, TransformSysAll, valueToMoney, valueToMoney2 } from "../../services/functions";
import Modalinfo from "../modal/info";
import InputPrecioPanel from "../InputPrecioPanel";
import Target from "../Target";
import Labelmoney from "../labelmoney";
import Modalgeneric from "../modal/generic";
import { useCustomQuery, useGetClientAsync } from "../../Hooks/useApis";
import {motion} from "framer-motion"
import GetPermiso from "../GetPermiso";
import LoadingModal from "../modal/LoadingModal";
import LabelFecha from "../LabelFecha";
import LabelNumber from "../LabelNumber";
import TableReactWindows from "../Reportes/Cash/TableReactWindows";
import { useRef } from "react";
import Modalsuccess from "../modal/success";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../Excel/DataToXlsx";
import { permisos } from "../../static/dat/datos";

const PanelTablaEsp = (props) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalEstadoPagoConfig, setmodalEstadoPagoConfig] = useState({}),
  [modalAddProveedorConfig, setmodalAddProveedorConfig] = useState({}),
  [modalFechaEstimada, setmodalFechaEstimada] = useState({}),
  [modalHistorialonfig, setmodalHistorialConfig] = useState({}),
  [modalPagoCliente, setmodalPagoCliente] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalChangeSurtidor,setModalChangeSurtidor] = useState({}),
  [pedidoCompra,setPedidoCompra] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [pagoCompra,setPagoCompra] = useState(null),
  [error,setError] = useState(null);

  const selectChangeSurtidor = useRef(""),inputMensaje=useRef("");
  const headerPes = props.headerPes;
  const fechaLimite = AddDiasFecha(GetFechaActualUTC(),-20);

  const permiso = havePermission(permisos.repPes);
  const fechaI = FechaLocalToUTC(props.PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(props.PropsReportes.fecha_Final,23,59,59);


  const configGetPermiso = {
    permission: permisos.desbPes,
    Successfunction: (data) => {
      setmodalFechaEstimada({ isOpen: true, title: "CAMBIAR FECHA ESTIMADA",}); 
    },
  };
  
  const CleanHeaderEsp=()=>{
    props.resetHeaderPes();
  }

    function validAmount(cantidad) {
      if (cantidad <= 0) {
        setmodalinfoConfig({
          isOpen: true,
          title: "Cantidad No Valida",
          message: "El valor no puede ser menor a uno",
        });
        return 1;
      }else{ return cantidad;}
    }
    function changeexist(item, e) {
      const cantidad = validAmount(parseInt(e.target.value));
      props.setCantidad(item.id, parseInt(cantidad));
    }
    function changeprecioventa(item,e){
      const precioVenta = moneyToValue(e);
      props.setPrecioVenta(item.id, precioVenta);
    }
    function porfecha(a, b) {
      if (a[0] < b[0]) return 1;
      if (a[0] > b[0]) return -1;
      return 0;
    }
    function IsChangeSurtidor(item) {
      let valid = IsEmisor(item);
      const fechaLimite = AddDiasFecha(GetFechaActual().fecha,-28);
      const fechaPes = FechaUTCToLocal(item.id.split('#')[1]).fecha;
      if('SOLICITADO'!=item.status||fechaPes<fechaLimite){ return false;}
      if(item.proveedores.length>0){
        let provValid = true;
        item.proveedores.forEach(prov => {
          if(prov.sucursal!==item.sucursal){
            provValid = false;
          }
        });
        if(!provValid){return false}
      }
      return valid
    }
    function IsEmisor(item) {
      return (item.sucursal == getSucursal()?true:false);
    }
    function IsRemitente(item) {
      return (item.remitente == getSucursal()?true:false);
    }
    function IsPesLocal(item) {
      return (item.remitente == getSucursal()&&item.sucursal== getSucursal()?true:false);
    }
    function IsAddSupplier(row) {
      const fechaPes = FechaUTCToLocal(row.id.split('#')[1]).fecha;
      if(row?.timeOut==true) return false;
      if(fechaPes<fechaLimite){return false}
      if(IsPesLocal(row)&&['ACEPTADO','PROCESO'].includes(row.status)){
        return true
      }
      else if(IsEmisor(row)&&['SOLICITADO'].includes(row.status)){
        return true;
      }else if(['ACEPTADO','PROCESO'].includes(row.status)){
        return true
      }else{
        return false;
      }
     // (row?.timeOut)?true:(IsPesLocal(row)?(row.status=="ACEPTADO"||row.status=="PROCESO"?false:true):(IsEmisor(row)?(row.status == "SOLICITADO"?false:true):(row.status=="ACEPTADO"||row.status=="PROCESO"?false:true)))
      
    }
    function IsCancelable(item){
      const fechaPes = FechaUTCToLocal(item.id.split('#')[1]).fecha;
      const today = GetFechaActual().fecha;
      let foundPaySupplier = false,difSuc=false;
      if(['FINALIZADO','CANCELADO'].includes(item.status)){return false}
      if(fechaPes<fechaLimite){return false}
      if(item.timeOut==true){return false}
      if(item.sucursal!=getSucursal()){return false}
      if(item.proveedores.length>0){
        item.proveedores.forEach(prov=>{
          if(prov.pagado>0){ foundPaySupplier = true;}
          if(prov.sucursal!=item.sucursal){ difSuc = true;}
        })
      }

      if(foundPaySupplier){return false}
      if(difSuc){return false}
      
      if(today==fechaPes){return true
      }else{
        if(difSuc) return false;
        if(item.sucursal!=item.remitente&&['RECIBIDO','ENVIADO','ENTREGADO'].includes(item.status)){return false}
        return true
      }
    }
    function PeticionUpdate(peticion){
      openmodalLoading();
      peticion.id=headerPes.itemSelected.id;
      peticion.sucursal=getSucursal();
      peticion.personal=getIdUser();
      peticion.nombrePersonal=getNombre();
  
      apiUse({
        url: "/update-pedido-especial",
        method: "POST",
        data: peticion,
        handleSuccessful: handleSuccessfulStatus,
        handleError: handleError,
      }) 
    }

  
    const handleSuccessfulStatus= (data) => {
      const header = {...headerPes};
      delete header.itemSelected;
      delete header.status;
      delete header.surtidor;
      props.setHeaderPes(header);

      setError(null);
      props.setFilterPes(headerPes.statusFilter);
      //useGetPedidosEsp(props.Cliente?.id || "",headerPes.statusFilter,[getSucursal()],headerPes.opcion,funcionExitoPes,handleError);
    }
    const handleError = (error) => {
      closemodalLoading();
      closeModalFechaEstimada();
      //console.log(JSON.stringify(error))
      setmodalinfoConfig({
        isOpen: true,
        title: error?.response?.data?.item || error ||"PES no actualizado",
        message:  error?.response?.data?.message ||"El valor no puede ser menor a uno",
      });
    }
    const handleSuccessfulEstadoPagos=(data)=>{
      data.compra.insertUpdate = true;
  
      setPedidoCompra(data.compra);
      setPagoCompra(data.compra.pagos.reduce((a, b) => parseInt(a) + parseInt(b.monto), 0));
      setmodalEstadoPagoConfig({
        isOpen: true,
        title: "Datos Compra",
      })
    }
    const funcionExitoPes = (data) => {
      closemodalLoading();
      let dat =(data.pedidos||data.datos);

      if (dat.length === 0) {
        setmodalinfoConfig({
          isOpen: true,
          message: "No hay pedidos pendientes'",
        });
        //dat=[{}];
      }
      props.setItemsP(GetItemsPes(dat));
    };
    const openModalSurtidor=()=> {
      setModalChangeSurtidor({isOpen: true, title:  "Cambiar de Surtidor"})
    }
    const closeModalChangeSurtidor=()=>{
      setModalChangeSurtidor({ isOpen: false})
    }
    function closeModalAddProveedor(e) {
      const newdataconfig = { ...modalAddProveedorConfig };
      newdataconfig.isOpen = false;
      setmodalAddProveedorConfig(newdataconfig);
      CleanHeaderEsp();
    }
    function closeModalEstadoPago(e) {
      const newdataconfig = { ...modalEstadoPagoConfig };
      newdataconfig.isOpen = false;
      setmodalEstadoPagoConfig(newdataconfig);
      const newpes ={...headerPes};
      delete newpes.itemSelected;
      props.setHeaderPes(newpes);
    }
    function closeModalinfo(e) {
      setmodalinfoConfig({ isOpen: false, title: "" });
      
    }
    function closeModalFechaEstimada(e) {
      const newdataconfig = { ...modalEstadoPagoConfig };
      newdataconfig.isOpen = false;
      setmodalFechaEstimada(newdataconfig);
      CleanHeaderEsp();
    }
    function closeModalPagoCliente(e) {
      const newdataconfig = { ...modalEstadoPagoConfig };
      newdataconfig.isOpen = false;
      setmodalPagoCliente(newdataconfig);
      props.addClient({});
    }
    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };
    const openmodalgeneric = (e) => {
      setmodalHistorialConfig({ isOpen: true });
    };
    const closemodalgeneric = (e) => {
      if((headerPes.itemSelected?.msjOrigen===true)||(headerPes.itemSelected?.msjSurtidor===true)){
        PeticionUpdate({status:"LEIDO"});
      }
      setmodalHistorialConfig({ isOpen: false });
      inputMensaje.current.value="";
    };
    const openModalSuccess=(message,titulo)=>{
      setmodalSuccessConfig({
        isOpen: true,
        title: titulo||"Solicitud Completa",
        message: message,        
      });
    }
    const closeModalsuccess=(e)=> {
      const newdataconfig = { ...modalSuccessConfig };
      newdataconfig.isOpen = false;
      setmodalSuccessConfig(newdataconfig);
    }

   useEffect(() => {
    if(!headerPes?.status){ return;}
    if(headerPes.itemSelected?.timeOut==true){
      PeticionUpdate({status:"HABILITAR",fechaEstimada:headerPes.fechaEstimada,motivo:"FUERA DE TIEMPO"});
    }
    else if(headerPes.status==="ACEPTADO"|| headerPes.status==="LEIDO" || headerPes.status == "ENVIADO" || headerPes.status==="RECIBIDO"|| headerPes.status==="ENTREGADO"){
      PeticionUpdate({status:headerPes.status});
    }
    else if(headerPes.status=="PROCESO"){
      if(headerPes.itemSelected?.status=="ENVIADO"){
        PeticionUpdate({status:headerPes.status,fechaEstimada:headerPes.fechaEstimada});
      }else if(headerPes.itemSelected.status=="PROCESO"){
        PeticionUpdate({status:headerPes.status,fechaEstimada:headerPes.fechaEstimada,motivo:"RETRASO EN ENTREGA DEL PRODUCTO"});
      }
      closeModalFechaEstimada();
    }
    else if(headerPes.status==="SOLICITADO"&&headerPes.itemSelected.edit==true){
      PeticionUpdate({status:"SOLICITADO",descripcion:headerPes.itemSelected.descripcion,precioVenta:headerPes.itemSelected.precioVenta,importe:headerPes.itemSelected.importe,cantidad:headerPes.itemSelected.cantidad});
    }
    else if(headerPes.status==="SURTIDOR"&&headerPes?.surtidor){
      closeModalChangeSurtidor();
      PeticionUpdate({status:"SURTIDOR",surtidor:headerPes.surtidor});
    }
  }, [headerPes.status]) 

  useEffect(() => {
    if(props.HeaderCompra.compraOk){
      const headerpes = props.HeaderCompra.headerpes,
      peticion={
        proveedores:[...headerpes.proveedor.proveedores, {proveedor:headerpes.proveedor.id,importe:headerpes.montoCompra,sucursal:getSucursal(),pagado:headerpes.pagado}],
        fechaEstimada:headerpes.fechaEstimada,
        id:props.HeaderCompra.id,
      };
      if(headerpes.status=="ACEPTADO"){
        peticion.status = "PROCESO";
      }else{
        peticion.status = headerpes.status;
      }

      openmodalLoading();
      peticion.sucursal=getSucursal();
      peticion.personal=getIdUser();
      peticion.nombrePersonal=getNombre();
      props.setCleanHeaderCompra();
  
      apiUse({
        url: "/update-pedido-especial",
        method: "POST",
        data: peticion,
        handleSuccessful: handleSuccessfulStatus,
        handleError: handleError,
      }) 
      
    }
  }, [props.HeaderCompra.compraOk]) 

   useEffect(() => {
    if(props.HeaderCompra.ventaOk){
      props.addClient({}); 
      props.setCleanHeaderCompra();
    }
  }, [props.HeaderCompra.ventaOk])  

  useEffect(() => {
    //if(!props?.sucursales?.length>0||props.HeaderCompra.ventaOk){return;}
    let peticion ={};
    let filtros = [];

    if(headerPes.opcion=="surtir"){
      peticion = {
        indexName: "sparsePesSuc",
        hashKey:{valor:("PE#"),nombre:"statusTemp"},
        sortKey:{valor:getSucursal(),operador:"eq",nombre:"remitente"},
      } 
    }else if(headerPes.statusFilter=="FINALIZADO"||headerPes.statusFilter=="CANCELADO"){
      peticion ={
        indexName:"reverseIndex",
        hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
        sortKey:{valor:[getSucursal()+"#"+fechaI,getSucursal()+"#"+fechaF],operador:"between",nombre:"id"},
      }
    }else{
      peticion = {
        indexName:"sparseIndex",
        hashKey:{valor:"PE#",nombre:"statusTemp"},
        sortKey:{valor:getSucursal()+"#",operador:"begins_with",nombre:"id"},
      }
    }
    
    if(!permiso){filtros.push({valor:getIdUser(),operador:"eq",nombre:"personal"});}
    if(props.Cliente?.id){filtros.push({valor:props.Cliente.id,operador:"eq",nombre:"cliente"});}
    if(headerPes.statusFilter&&headerPes.opcion!="surtir"){filtros.push({nombre:"status",valor:headerPes.statusFilter,operador:"eq"});}
    if(filtros){peticion.filters = filtros}
    //console.log(peticion)
    //setPes(true);
    openmodalLoading();
    //useGetPedidosEsp(props.Cliente?.id || "",headerPes.statusFilter,[getSucursal()],headerPes.opcion,funcionExitoPes,handleError);
    useCustomQuery(peticion,funcionExitoPes,handleError);
  }, [headerPes.change,headerPes.opcion]) 

  useEffect(()=>{
    if(!props.VentaAPagar?.id){return;}
    if(!headerPes.itemSelected.cliente){return}
    const getCliente =async()=>{
      openmodalLoading();
      try{
        const response =  await useGetClientAsync({cliente:headerPes.itemSelected.cliente});
        const client = response.data[0] || {};
        closemodalLoading();
        if(!client?.id){ handleError("El cliente no se encontro en la BD"); return;}
        props.addClienteVenta(client);
        props.addRuta("PagosPes");
      }catch (error){
        handleError(error)
      }
    }

   
    getCliente();
  },[props.VentaAPagar?.id])

  return (
    <>
      <LoadingModal config={modalLoading} >
          <LoadingModal.Body>
            <LoadingModal.Title>{modalLoading.titulo||"Cargando Pedidos"}</LoadingModal.Title>
          </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      
      <Modalgeneric  h="size-h-30" w="size-w-40" config={modalChangeSurtidor}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{modalChangeSurtidor.title}</Modalgeneric.Title>
          <div className="flex flex-col gap-2 items-center w-full">
              <label className="textf">Surtidor</label>
              <select
              defaultValue={""}
              ref={selectChangeSurtidor}
              className=" border-form w-full md:w-32">
                {<option key={-1} defaultValue=""></option>}
                {props?.allSucursales.sort().map((item,index)=>(
                    <option key={index} defaultValue={item}>{item}</option>
                ))}
              </select>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-blue"
            onClick={(e) => {
              if(!selectChangeSurtidor.current.value){handleError("Sucursal no valida","Debe seleccionar alguna sucursal"); return;}
              props.setHeaderPes({...headerPes,status:"SURTIDOR",surtidor:selectChangeSurtidor.current.value});
            }}
          >
            Cambiar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closeModalChangeSurtidor}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric bg="bg-bluei" w="size-w-30" h="sie-h-50" config={modalAddProveedorConfig}>
        <Modalgeneric.Body>
        <div className="flex flex-col w-full justify-between items-center text-white gap-3">
          <label htmlFor="fede" className="text-center md:text-xl ">Proveedor</label>
          <select
              className={"border-form md:text-sm lg:text-base bg-white text-center rounded w-full"}
              value={headerPes.proveedor?.id||""}
              onChange={e => {
                let proveedor = {};
                if(e.target?.value){
                    let prov = props.proveedores[1].filter(proveedor=>(proveedor.id == e.target.value));
                    prov[0].proveedores = headerPes.itemSelected.proveedores;
                    if(prov){proveedor =prov[0];}
                }
                props.setHeaderPes({...headerPes,proveedor:proveedor})
              }}
              >
              <option key={-1} defaultValue=""></option>
              {(headerPes.suppliersSelected.length==0?(props.proveedores.length>0?props.proveedores[1].map((proveedor,index)=>(
                <option key={proveedor.id} value={proveedor.id} defaultValue={proveedor.nombre}>{proveedor.nombre.toUpperCase()}</option>
              )):[]):headerPes.suppliersSelected.map(proveedor=>(
                <option key={proveedor.id} value={proveedor.id} defaultValue={proveedor.nombre}>{proveedor.nombre.toUpperCase()}</option>
              )))}
            </select>
          <label htmlFor="fc" className="text-center md:text-xl">Folio Compra</label>
          <input 
          id="fc"
          className="border-form bg-white w-full lg:w-1/2"
          defaultValue={props.HeaderCompra?.referencia||""}
          onBlur={e=>{
            props.setCompra({...props.HeaderCompra,referencia:e.target.value});
          }}
          ></input>
          <label htmlFor="fede" className="text-center md:text-xl ">Monto</label>
          <label htmlFor="fede" className="text-center text-sm ">Restante: {valueToMoney(headerPes.itemSelected?.importe-(headerPes.itemSelected?.precioCompra||0))}</label>
          <InputPrecioPanel
            className="lg:w-1/2 bg-white"
            min={1}
            form={true}
            max={!headerPes.itemSelected?.garantia?(headerPes.itemSelected?.importe?headerPes.itemSelected.importe:0)-(headerPes.itemSelected?.precioCompra?headerPes.itemSelected.precioCompra:0):""}
            defaultValue={headerPes.montoCompra}
            setValor={(valor) => { props.setHeaderPes({...headerPes,montoCompra:moneyToValue(valor)});}}
          />
          <label htmlFor="fede" className="text-center md:text-xl ">Fecha Estimada de Entrega</label>
          <input 
            id={"fede"}
            type='date'
            min={GetFechaActual().fecha}
            className="border-form bg-white w-full lg:w-1/2"
            value={headerPes.fechaEstimada}
            onChange={e=>{
              let fecha = "";
              if(GetFechaNoWeekEnds(e.target.value)){fecha=e.target.value;}
              props.setHeaderPes({...headerPes,fechaEstimada:fecha})
            }}
          />
          <label htmlFor="fede" className="font-bold text-center text-ambar-400 break-words" hidden={error?false:true}>{error}</label>
        </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalAddProveedor}
          >
            Cerrar
          </button>
          <button
            type="button"
            className="btn-green1 "
            onClick={e=>{
              if(Object.keys(headerPes.proveedor).length>0 && (headerPes.montoCompra>0 && headerPes.montoCompra <= headerPes.itemSelected.importe && headerPes.fechaEstimada!=="")){
                const pes = {...headerPes};
                if(headerPes.status=="ACEPTADO"){
                  pes.status = "PROCESO";
                }else{
                  pes.status = headerPes.itemSelected.status;
                }
                props.setCompra({...props.HeaderCompra,id:headerPes.itemSelected.id,importe:headerPes.montoCompra,headerpes:pes})
                props.addProveedor(headerPes.proveedor);
                props.addRuta("PagosCompra"); 

              }else{
                setError("Todos los campos son obligatorios");
              }
            }}
          >
            Guardar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric bg="bg-bluei" w="size-w-30" h="size-h-50" config={modalEstadoPagoConfig}>
        <Modalgeneric.Body>
        <div className="flex flex-col w-full px-1 text-center justify-between items-center font-bold text-white ">
          <label htmlFor="fede" className="text-ambar-200">Proveedor</label>
          <label htmlFor="fede">{pedidoCompra?.proveedor?pedidoCompra.proveedor:""}</label>
          <label htmlFor="fede" className="text-ambar-200">Folio Compra</label>
          <label htmlFor="fede" >{pedidoCompra?.folio?pedidoCompra.folio:"S/F"}</label>
          <label htmlFor="fede" className="text-ambar-200">Precio Compra</label>
          <Labelmoney twoDecimal={true} className="w-auto font-bold ">{pedidoCompra?.importe}</Labelmoney>
          <label htmlFor="fede" className="text-ambar-200">Pagos</label>
          <div className="flex flex-col w-full justify-center items-center border border-dashed border-white" >
          {pedidoCompra?.pagos?pedidoCompra.pagos.map((pago,index)=>(
              <div key={index} className="flex flex-wrap justify-between w-full">
                <div >
                  {pago.metodo}
                  <Labelmoney twoDecimal={true} className=" font-bold">{pago.monto}</Labelmoney>
                </div>
                <label htmlFor="fede">{FechaUTCToLocal(pago.fecha).fecha+" "+FechaUTCToLocal(pago.fecha).hora}</label>
              </div>
          )):""} 
          </div>
        </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className={"btn-gray2 w-full "}
            onClick={closeModalEstadoPago}
          >
            Cerrar
          </button>
            <button
            type="button"
            className="btn-green1 w-full "
            disabled={(getSucursal()==pedidoCompra.sucursal?(pagoCompra<pedidoCompra.importe?false:true):true)}
            onClick={e=>{
              
              props.setCompra(pedidoCompra);
              let prov = props.proveedores[1].filter(proveedor=>(proveedor.id == pedidoCompra.proveedor));
              props.addProveedor(prov[0]);

              props.addRuta("PagosCompra");
            }}>
            {pagoCompra<pedidoCompra.importe?"Abonar":""}
            </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric bg="bg-bluei" w="size-w-30" h="size-h-30" config={modalFechaEstimada}>
        <Modalgeneric.Body>
        <div className="flex flex-col w-full justify-between items-center font-bold text-white">
          <label htmlFor="fede" className="text-center md:text-xl ">Nueva Fecha</label>
          <input 
            type='date'
            min={GetFechaActual().fecha}
            className="border-form bg-white "
            value={headerPes.fechaEstimada}
            onChange={e=>{
              let fecha = "";
              if(GetFechaNoWeekEnds(e.target.value)){fecha=e.target.value;}
              props.setHeaderPes({...headerPes,fechaEstimada:fecha})
            }}
          />
          <label htmlFor="fede" className="text-ambar-200" hidden={!error}>No puede ser el mismo dia, ni fines de semana</label>
        </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className={"btn-gray2 w-full "}
            onClick={closeModalFechaEstimada}
          >
            Cerrar
          </button>
            <button
            type="button"
            className="btn-green1 w-full"
            onClick={e=>{
              closeModalFechaEstimada();
              if(headerPes.fechaEstimada!==GetFechaActual().fecha&&headerPes.fechaEstimada!==""){
                const estado =(headerPes.itemSelected?.timeOut==true?"HABILITAR":"PROCESO")
                props.setHeaderPes({...headerPes,status:estado});
              setError(false);
            }else{setError(true);}
            }}>
             Cambiar
            </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric bg="bg-bluei" w="size-w-30" h="size-h-50" config={modalPagoCliente}>
        <Modalgeneric.Body>
        <div className="flex flex-col w-full justify-center items-center font-bold text-white pt-2">
          <label htmlFor="fede" className="text-ambar-200">Cliente</label>
          <label htmlFor="fede">{headerPes.itemSelected?.cliente?TransformSysAll(headerPes.itemSelected.cliente):""}</label>
          <label htmlFor="fede" className="text-ambar-200">Importe Venta</label>
          <Labelmoney twoDecimal={true} className="w-fullbfont-bold">{headerPes.itemSelected?.importe||0}</Labelmoney>
          <label htmlFor="fede" className="text-ambar-200">Pagos</label>
          <div className="flex flex-col w-full items-center border border-dashed border-white" >
          {headerPes.itemSelected?.pagos?headerPes.itemSelected.pagos.map((pago,index)=>(
              <div key={index} className="flex flex-wrap justify-between w-full">
                <div className="justify-center">
                  {pago.metodo}
                  <Labelmoney twoDecimal={true} className="w-24 font-bold">{pago.monto}</Labelmoney>
                </div>
                <label htmlFor="fede">{FechaUTCToLocal(pago.fecha).fecha+" "+FechaUTCToLocal(pago.fecha).hora}</label>
              </div>
          )):""} 
          </div>
        </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className={"btn-gray2 w-full "}
            onClick={closeModalPagoCliente}
          >
            Cerrar
          </button>
            <button
            type="button"
            className="btn-green1 w-full "
            disabled={((headerPes.itemSelected?.pagos?headerPes.itemSelected.pagos.reduce((a, b) => parseInt(a) + parseInt(b.monto), 0):0)<headerPes.itemSelected?.importe||0?false:true)}
            onClick={(e)=>{
              if(e.detail>1){return;}
              if(headerPes.itemSelected?.pagos.length==0){
                 props.SetVentaAPagar({
                  id: headerPes.itemSelected.id,
                  sucursal: getSucursal(),
                  total: headerPes.itemSelected.importe,
                  pagos: headerPes.itemSelected.pagos,
                  cliente: headerPes.itemSelected.cliente,
                  headerpes: headerPes,
                });
              }else{
                props.SetVentaAPagar({
                  cliente: headerPes.itemSelected.cliente,
                  id: headerPes.itemSelected.id,
                  nombreVendedor: getNombre(),
                  numeroArticulos: headerPes.itemSelected.cantidad,
                  sucursal: getSucursal(),
                  total: headerPes.itemSelected.importe - headerPes.itemSelected.pagado,
                  utilidad: headerPes.itemSelected.precioVenta - (headerPes.itemSelected.precioCompra||0),
                  vendedor: getIdUser(),
                  pagado: headerPes.itemSelected.pagado,
                  pagos:headerPes.itemSelected.pagos,
                  recibido : 0,
                  cambio: 0,
                  restante: 0,
                  shortId: headerPes.itemSelected.shortId,
                  headerpes: headerPes,
                  cob:true,
                });
              }
            
            }}>
            Abonar
            </button>
            <button
            type="button"
            className={"btn-blue1 w-full "}
            onClick={e=>{
              const ticket = {
                item:headerPes.itemSelected,
                pagos:headerPes.itemSelected.pagos,
                pagado:headerPes.itemSelected.pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0),
                id:headerPes.itemSelected.id,
                cliente:headerPes.itemSelected.clientE,
                subtotal:headerPes.itemSelected.importe,
                iva:0,
                total: headerPes.itemSelected.importe
              };
           
              props.SetTicket(ticket);
              props.addRuta("ticketCopiaPes");
            }}
          >
            Imp Copia
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric w="size-w-60" h="size-h-80" config={modalHistorialonfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{("Historial Pedido: "+ GetFecha(headerPes.itemSelected?.id?headerPes.itemSelected.id:""))}</Modalgeneric.Title>
          <div className="flex flex-col justify-center gap-1 w-full h-95/100">
            <div className="size-form w-full gap-1 items-center ">
              <label htmlFor="fede" className="font-bold text-center">Mensaje</label>
              <textarea
                disabled={headerPes.itemSelected?.status==="CANCELADO"}
                className="mt-2 border-form-free w-full  "
                rows="3"
                ref={inputMensaje}
              ></textarea>
            </div>

              <div className="w-full  p-1 border-2 rounded-lg bg-bluei text-base text-gray-700 transition-colors duration-200 ease-in-out h-full  overflow-y-auto" >
                {headerPes.itemSelected?.historial
                  ?headerPes.itemSelected.historial.sort(porfecha).map((mensaje, index) => {
                    const fecha = Object.keys(mensaje)[0],msg=  Object.values(mensaje)[0];
                    return( <div key={index} className="size-form text-size w-full mb-1">
                      <div className=" font-medium py-2 px-2 rounded-lg text-black bg-teal-200 ">
                        <span className="text-xs mr-2 font-normal">{FechaUTCToLocal(fecha).fecha+" "+FechaUTCToLocal(fecha).hora}</span>
                        {msg}
                      </div>
                    </div>)
                    })
                  :""}
              </div>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className={"btn-blue"}
            onClick={(e) => {
              if(!inputMensaje.current?.value){return;}
              if(headerPes.status=="CANCELADO"||headerPes.status=="GARANTIA"){
                PeticionUpdate({status: headerPes.status,motivo: inputMensaje.current.value.toUpperCase()});
              }else{ 
                PeticionUpdate({status: "MENSAJE",mensaje: inputMensaje.current.value.toUpperCase()});
              }
              closemodalgeneric();
            }}
          >
            Enviar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <TableReactWindows
       bgHeader="bg-blue-800 text-white"
       rowHeight={55}
       btnDownload={async(e,datos)=>{
        const data = datos||props.itemsPedido;
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="PES"+(headerPes?.statusFilter?"-"+headerPes?.statusFilter:""),
        headerFile="Reporte de Pedido Especial "+(headerPes?.statusFilter=="FINALIZADO"?"FINALIZADOS del "+props.PropsReportes?.fecha_Inicial+" al "+props.PropsReportes?.fecha_Final:"del "+props.PropsReportes?.fecha_Final),
        sheet1 = workb.sheet(0),sheet2=workb.addSheet("Proveedores"),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Folio':12,'Fecha':13,'Cliente':13,'Status':15,'Articulos':80,'Importe Compra':17,'Importe Venta':17};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((pes,index) => {
          let articulos ="";
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(pes?.shortId||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(FechaUTCToLocal(pes.date).fecha);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(TransformSysAll(pes?.cliente)||"");
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(pes?.status||"");
    
          pes.items.forEach((item,ind)=>{articulos += item.cantidad + " "+ item.descripcion + (ind<pes.items.length-1?"\n":"");  })
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(articulos);
          if(pes.items.length>1){
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).row().height(15*pes.items.length);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style("wrapText",true);
          }
    
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(pes?.precioCompra||0));
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(pes?.importe||0));
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
        });
    
        sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);


        sheet2.cell("A1").value("Reporte de Pedido Especial por Proveedor del "+props.PropsReportes?.fecha_Final);
        sheet2.cell("A1").style("bold",true);
        sheet2.cell("A1").style("fontSize",18);

        const header2=['Folio','Fecha','Cliente','Proveedor','Importe'],
        sizeColumn2=[12,13,13,15,15];
        let cont=0;

        header2.forEach((value,index)=>{
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).value(value);
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet2.column(columnasXlsx[index]).width(sizeColumn2[index]);
        })

        data.forEach((pes,index) => {
          pes.proveedores.forEach(prov=>{
            sheet2.cell(columnasXlsx[0]+(colPlus+cont)).value(pes?.shortId||"");
            sheet2.cell(columnasXlsx[1]+(colPlus+cont)).value(FechaUTCToLocal(pes.date).fecha);
            sheet2.cell(columnasXlsx[2]+(colPlus+cont)).value(TransformSysAll(pes?.cliente)||"");
            sheet2.cell(columnasXlsx[3]+(colPlus+cont)).value(prov?.proveedor||"");
            sheet2.cell(columnasXlsx[4]+(colPlus+cont)).value(valueToMoney2(prov?.importe||0));
            sheet2.cell(columnasXlsx[4]+(colPlus+cont)).style(formatoCelda.contabilidad);
            cont++
          })
        })

        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).style(formatoCelda.contabilidad);
            
        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
       columns={[
        {
          key:"shortId",
          header: "N°_Folio",
          width:75,
          type: tipoCelda.text,
          filter:true,
        },
        {
          key:"date",
          header:"Fecha E.",
          width:110,
          filter:true,
          type:"date",
          // eslint-disable-next-line react/display-name
          content:({row,index})=>(
            <div key={index+"fechaE"}  className="flex flex-wrap gap-1 w-full justify-between items-center">
               <LabelFecha className={"classCellMediumFree "} hour={true}>{row.id.split('#')[1]}</LabelFecha>
                
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Cancelar Pedido"
                className={(IsCancelable(row)?"rounded-full text-sm text-red-700 hover:text-red-500":"hidden")}//&&IsEmisor(row)&&row.status=="SOLICITADO"
                onClick={e=>{
                  props.setHeaderPes({...headerPes,status:"CANCELADO",itemSelected:{...row}});
                  openmodalgeneric();
                }}>
                <i className="fas fa-times-circle"/>
                </motion.button>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Garantia PES"
                className={(row?.timeOut==false&&IsEmisor(row)&&(row.status=="RECIBIDO"||row.status=="CANCELADO")?"rounded-full text-sm text-orange-300 hover:text-orange-500":"hidden")}
                onClick={e=>{
                  props.setHeaderPes({...headerPes,status:"GARANTIA",itemSelected:{...row}});
                  openmodalgeneric();
                }}>
                  <i className="fas fa-ambulance"/>
                </motion.button>
              </div>
          )
        },
        {
          key:"fechaEstimada",
          header: "Fecha Estimada",
          width:160,
          filter:true,
           // eslint-disable-next-line react/display-name
          content:({row,index})=>(
            <div key={index+"fechaE"}  className="flex flex-wrap  w-full justify-start gap-1 items-center">
            <label htmlFor="fede" className="classCellMediumFree ">
              {row?.fechaEstimada||""}
            </label>
            <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
            title="Cambiar Fecha" 
            className={(row?.timeOut==false&&IsRemitente(row)&&(row?.status=="PROCESO"||row?.status=="ENVIADO")?"rounded-full text-sm text-black hover:text-blue-600":"hidden")}
            onClick={e=>{
              setmodalFechaEstimada({ isOpen: true, title: "CAMBIAR FECHA ESTIMADA",}); 
              props.setHeaderPes({...headerPes,itemSelected:{...row}});
            }}>
              <i className="far fa-calendar text-lg"/>
            </motion.button>
            <label htmlFor="fede" className={(!row?.timeOut&&RestaFechas(GetFechaActual().fecha,row?.fechaEstimada)>2&&RestaFechas(GetFechaActual().fecha,row?.fechaEstimada)<5&&row?.status!=="ENVIADO"&&row?.status!=="ENTREGADO"&&row?.status!=="RECIBIDO"?" text-base text-yellow-500 font-bold w-10":"hidden ")}>{RestaFechas(GetFechaActual().fecha,row?.fechaEstimada)}</label>
            <label htmlFor="fede" className={(!row?.timeOut&&RestaFechas(GetFechaActual().fecha,row?.fechaEstimada)<=2&&row?.status!=="ENVIADO"&&row?.status!=="ENTREGADO"&&row?.status!=="RECIBIDO"&&row?.status!="FINALIZADO"?"rounded-full text-base overflow-visible text-red-500 font-bold w-10":"hidden")}>{RestaFechas(GetFechaActual().fecha,row?.fechaEstimada)}</label>
            <GetPermiso {...configGetPermiso}>
              <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
              title="Bloqueado" 
              className={(row?.timeOut&&row?.status!=="ENTREGADO"&&row?.status!=="RECIBIDO"?"rounded-full text-sm ":"hidden")}
              onClick={e=>{props.setHeaderPes({...headerPes,itemSelected:{...row}});}}>
                <i className="text-red-500 fas fa-lock"/>
              </motion.button>
            </GetPermiso>
            
          </div>
          )
        },
        {
          key:"cliente",
          header: "Cliente",
          width:130,
          filter:true,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) => (
              <div key={index+"cliente"} className={"flex w-full gap-2 justify-between rows-center"}>
                <p htmlFor="fede" className={IsEmisor(row)?"classCellMedium":""}>{TransformSysAll(row?.cliente)}</p>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Pago Cliente" 
                className={(row?.timeOut==false&&IsEmisor(row)&&row.status!=="CANCELADO"?(row?.pagado==row?.importe?"hidden":"rounded-full text-sm text-ambar-400 hover:text-ambar-600"):"hidden")}
                onClick={e=>{
                 // console.log('row==>',row)
                  props.setHeaderPes({...headerPes,itemSelected:{...row}});
                  setmodalPagoCliente({
                  isOpen: true,
                  title: "Pagos Cliente",
                  })
                }}>
                  <i className="fas fa-coins text-lg"/>
                </motion.button>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Imprimir Ticket" 
                className={(IsEmisor(row)&&row.status!=="CANCELADO"&&row?.pagado==row?.importe?"rounded-full text-sm text-gray-400 hover:text-sky-300":"hidden")}
                onClick={e=>{
                  const ticket = {
                    item:row,
                    pagos:row.pagos,
                    pagado:row.pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0),
                    id:row.id,
                    cliente:row.cliente,
                    subtotal:row.importe,
                    iva:0,
                    total: row.importe
                  };
               
                  props.SetTicket(ticket);
                  props.addRuta("ticketCopiaPes");
                }}>
                  <i className="fas fa-receipt text-lg"/>
                </motion.button>
              </div>
            )
        },
        {
          key:"sucursal",
          header: "Enviar a",
          width:85,
          type:tipoCelda.text,
          className:"font-bold text-center",
          filter:true,
          
        },
        {
          key:"remitente",
          header:"Surtidor",
          width:95,
          filter:true,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) => (
            <div key={index+"cliente"} className={"flex w-full gap-2 justify-between"}>
                <p htmlFor="fede" className={"classCellMedium"}>{row?.remitente}</p>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Cambio Surtidor" 
                className={(IsChangeSurtidor(row)?"rounded-full text-sm text-teal-500 hover:text-teal-600":"hidden")}
                onClick={e=>{
                  props.setHeaderPes({...headerPes,itemSelected:{...row}});
                  openModalSurtidor();
                }}>
                  <i className="fas fa-edit "/>
                </motion.button>
            </div>
          )
        },
        {
          key:"status",
          header:"Status",
          width:150,
          filter:true,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) => (
            <div key={index+"status"}  className={(!row?.timeOut?"flex justify-between gap-1 w-full ":"hidden")}>
              <label  htmlFor="fede" className={" font-extrabold text-center "+(row?.status?("texts-"+row.status):"text-black")}>{row.status}</label>
              
              <label htmlFor="fede" title="Cancelado" className={(!row.timeOut&&row?.status=="CANCELADO"?"rounded-full text-sm overflow-visible":"hidden")}><i className="text-red-500 fas fa-ban"/></label>
              <label htmlFor="fede" title="Garantia" className={(row?.garantia?"rounded-full overflow-visible":"hidden")}><i className="text-yellow-500 fas fa-sync-alt"/></label>

              <div className={(IsRemitente(row)?"":"hidden")}>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Aceptar PES"
                className={(row?.timeOut==true?"hidden":(row?.status=="SOLICITADO" ?"text-green-500 hover:text-green-600":"hidden"))}
                onClick={(e) => {
                  props.setHeaderPes({...headerPes,status:"ACEPTADO",itemSelected:{...row}});
                }}>
                  <i className="fas fa-check-circle text-lg"></i>
                </motion.button>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Enviar PES"
                className={((row.status=="ACEPTADO" || row.status =="PROCESO")&&row.proveedores.length>0?"text-blue-600 hover:text-blue-700":"hidden w-0")}
                onClick={(e) => {
                  props.setHeaderPes({...headerPes,status:"ENVIADO",itemSelected:{...row}});
                }}>
                  <i className="fas fa-shipping-fast text-lg"></i>
                </motion.button>
              </div>
              <div className={(row.sucursal==getSucursal()?"":"hidden")}>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Recibir PES"
                className={(row?.status=="ENVIADO"?"text-ambar-700 hover:text-ambar-800":"hidden")}
                onClick={(e) => {
                  props.setHeaderPes({...headerPes,status:"RECIBIDO",itemSelected:{...row}});
                }}>
                  <i className="fas fa-box-open text-lg"></i>
                </motion.button>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                title="Entregar PES"
                className={(row?.status=="RECIBIDO"?"text-blue-600 hover:text-blue-700":"hidden")}
                onClick={(e) => {
                  props.setHeaderPes({...headerPes,status:"ENTREGADO",itemSelected:{...row}});
                }}>
                  <i className="fas fa-people-carry text-lg"></i>
                </motion.button>
              </div>
            </div>
          )
        },
        {
          key:"descripcion",
          header:"Descripcion",
          width:520,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) => (
            <div className="flex flex-col w-full justify-start overflow-x-auto overflow-y-auto relative max-h-9/9">
            {row?.items?
            
              row.items.map((element,index)=>(
                <div key={index} className="w-full flex gap-2 justify-items-start items-center justify-start">
                  <Labelmoney className="w-24 font-semibold text-xs md:text-sm 2xl:text-lg cursor-text " twoDecimal={true}  >{element?.precioVenta}</Labelmoney>
                  <LabelNumber className="w-5">{element?.cantidad}</LabelNumber>
                  <p  key={index}
                  className={"w-full font-size text-2xs md:text-xs resize-none focus:outline-none uppercase "+(row?.edit?"bg-white":"bg-transparent")}>
                    {element.descripcion}
                  </p>
                </div>
              ))
            
            :""}
          </div>
          ),
        },
        {
          key:"precioCompra",
          header:"Imp Compra",
          width:110,
          filter:true,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) =>(
            <Labelmoney twoDecimal={true} className={headerPes.showPrecioCompra?"classCellMedium ":"hidden"}>{row?.precioCompra}</Labelmoney>
          ) 
        },
        {
          key:"importe",
          header: "Imp Venta",
          width: 110,
          type:tipoCelda.money,
          filter:true,
          footer:true,
          function:getTotal,
        },
        {
          key:"proveedores",
          header: "Proveedor",
          width:250,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) =>
          <div className={"flex flex-wrap gap-1 justify-start items-center w-full overflow-y-auto relative max-h-9/9"}>
            {row?.proveedores?row.proveedores.map((prov,index)=>(
             <Target
                disabled={row?.timeOut}
                valor={prov.proveedor}
                key={index}
                bg={(getSucursal()==prov.sucursal?false:true)}
                getValor={idProveedor=>{
                  const itemselect = {... row};
                  itemselect.proveedores = row.proveedores.filter(pr=>(pr.proveedor == idProveedor))[0];
                  //setItemSelected({...itemSelected,itemselect});
                   let peticion ={
                    proveedor: prov.proveedor,
                    id: row.id,
                    sucursal: prov.sucursal
                  };
                   apiUse({
                    url: "/get-compra-especial",
                    method: "POST",
                    data: peticion,
                    handleSuccessful: handleSuccessfulEstadoPagos,
                    handleError: handleError,
                  })  
                }}
                /> 
            )):""}
            <div  className={IsAddSupplier(row)?"text-blue-800 ":"hidden"}>
              <button
                onClick={(e) => {
                    let suppliersSelected =[];
                    row.proveedores.forEach(prov => {suppliersSelected.push(prov.proveedor);});
                    props.setHeaderPes({...headerPes,
                      suppliersSelected:(props.proveedores[1].filter(elemento => suppliersSelected.indexOf(elemento.id) == -1) ),
                      itemSelected:{...row}});
                    setmodalAddProveedorConfig({ isOpen: true, title: "Compra a Proveedor",});
                }}
              >
              <i className="fas fa-plus-circle text-lg transform hover:text-blue-500 hover:scale-110"></i>
              </button>
            </div> 
          </div>
        },
        {
          key:"msjSutrido",
          header: "Chat",
          width:80,
          // eslint-disable-next-line react/display-name
          content: ({row,index}) => ( 
          <div className="flex flex-wrap w-14 justify-between">
            <div 
              className=" rounded w-6 h-5 hover:bg-blue-900 " 
              hidden={row?.timeOut}
              onClick={e=>{
                props.setHeaderPes({...headerPes,itemSelected:{...row}});
                openmodalgeneric();
              }}>
                <i  className={"fas fa-envelope text-2xl hover:text-white  "+(((IsEmisor(row)&&row?.msjSurtidor==true)||(IsRemitente(row)&&row?.msjOrigen===true))?" text-green-500":" text-yellow-400")}></i>
            </div>
            <div className={(row?.timeOut==true?"hidden":(IsEmisor(row)&&(row.status=="BORRADOR" || row.status =="SOLICITADO")?"":"hidden"))}>
              <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
                  className={(row?.edit?"text-teal-600 ml-1 hover:text-blue-600":"hidden")}
                  onClick={(e) => {
                    if(!row?.old){return;}
                    if(row.old.importe==row.importe&&row.old.precioVenta==row.precioVenta&&row.old.descripcion==row.descripcion){
                      const newItem ={...row};
                      delete newItem.old;
                      delete newItem.edit;
                      props.setItem(newItem)

                      return;
                    }
                    props.setHeaderPes({...headerPes,status:"SOLICITADO",itemSelected:{...row}});
                  }}>
                    <i className="fas fa-save text-lg"></i>
              </motion.button>
              <motion.button whileHover={{scale:1.2}} whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}} 
              className={(!row?.edit?"text-pink-400 ml-1 hover:text-red-500":"hidden")}
              onClick={(e) => {
                props.setItem({...row,edit:true,old:{...row}});
              }}>
                <i className="fas fa-pen-alt text-lg"></i>
              </motion.button>
            </div>
          </div>)
        },
        ]}
        footer={[
          {
            key:"shortId",
            width:75,
          },
          {
            key:"id",
            width:110
          },  
          {
            key:"fechaEstimada",
            width:160,
          },
          {
            key:"cliente",
            width:95,
          },{},{},{},
          {
            key:"descripcion",
            width:550,
            // eslint-disable-next-line react/display-name
            content:({row,index})=>(<label className="w-full">Totales:</label>)
          },
          {

          },
          {
            key:"importe",
            width:110,
            type:tipoCelda.money,
            function:getTotal,
          },
          {},{},{}
        ]}
        data={props.itemsPedido}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCantidad: (id, cantidad) => dispatch(setCantidadEsp(id, cantidad)),
  setPrecioVenta: (id,precioVenta) => dispatch({type: "SET_PRECIOVENTA_ESP",id: id,precioVenta: precioVenta,}),
  setDescripcionPes:(id,descripcion) => dispatch({type:"SET_DESCRIPCION_ESP",id:id, descripcion:descripcion}),
  setItem:(item)=> dispatch({type: "SET_ITEM_ESP",item: item,}),

  addRuta: (ruta) => dispatch(setRuta(ruta)),
  addProveedor: (proveedor) => dispatch({type: "SET_SEARCHPROVEEDOR",proveedor: proveedor,}),
  addClient: (cliente) =>dispatch({ type: "ADD_CLIENT",item: cliente}),
  SetVentaAPagar: (datos) =>dispatch({type: "SET_VENTAAPAGAR", ventaapagar: datos}),

  addClienteVenta: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),

  setCleanHeaderCompra:() => dispatch({type:"CLEAN_HEADERCOMPRA"}),
  setCompra: compra => dispatch({type:"SET_HEADER_COMPRA",compra:compra}),

  resetHeaderPes :()=>dispatch({type:"RESET_HEADER_PES"}),
  setHeaderPes:(pedido)=> dispatch({type:"SET_HEADER_PES",pedido:pedido}),
  setItemsP:(items)=> dispatch({type:"SET_ITEMS_ESP",items:items}),

  setFilterPes:(filtro)=>dispatch({type:"SET_FILTER_PES",filter:filtro}),
  SetTicket: (datos) =>
  dispatch({
    type: "SET_TICKET",
    ticket: datos,
  }),
});


const mapStateToProps = (state) => ({
  HeaderCompra: state.HeaderCompra,
  itemsPedido: state.itemsPedidoEspecial,
  Cliente: state.Clientes,
  VentaAPagar: state.VentaAPagar,
  headerPes: state.HeaderPes,
  PropsReportes: state.PropsReportes,
  allSucursales: state.Catalogos.sucursales,
  ClientesVenta: state.ClientesVenta,
  Ruta: state.Ruta
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelTablaEsp);
