import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import LoadingModal from '../modal/LoadingModal';
import Modaldanger from "../modal/danger";
import { dividirArray, FechaLocalToUTC, getArrayAsync, getSucursal, getTotal, OrderArrayZA, tipoCelda } from '../../services/functions';
import { columnasXlsx, GenerateXlsx, SaveXlsx } from '../Excel/DataToXlsx';
import TableReactWindows from './Cash/TableReactWindows';
import { useCatalogoAsync } from '../../Hooks/useApis';
import InputSelectormap from '../Productos/InputSelectormap';
import CalendarModal from '../modal/CalendarModal';

export const ReporteVentaClienteLinea = () => {
  const [modalLoading, setmodalLoading] = useState({}),
  [modalError,setModalError]=useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [valores,setValores] = useState([]),
  [clientesTotal,setClientesTotal] = useState([]),
  [lineas,setLineas] = useState([]),
  [linea,setLinea] = useState(""),
  [openModalCalendar,setOpenModalCalendar]=useState(false),
  [fechaCalendar,setFechaCalendar] = useState({});

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  const handleError = (error) => {
    closemodalLoading();
    setModalError({isOpen:true,title:"Error de Red",message:error.message||error});
  }
  const closeModalError=()=>{ setModalError({isOpen:false}); }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const getVentasAsync=async()=>{
    const peticionLinea = {
        indexName:"sparseIndex",
        hashKey:{valor:("VENTA#"+getSucursal()+"#LINEA#"+linea),nombre:"statusTemp"},
        sortKey:{valor:[FechaLocalToUTC(fechaCalendar.fecha_Inicial),FechaLocalToUTC(fechaCalendar.fecha_Final,23,59,59)],operador:"between",nombre:"id"}
    },
    peticionesVentas=[];

    try{
      openmodalLoading();
      const respLineas = await getArrayAsync(peticionLinea,[]),clienteAcomulado ={};
      let ventas =[],idUnicos =[],newLineas =[...respLineas],clienteTotal=[];

      idUnicos = respLineas.map(item=>item.id);
      idUnicos = [...new Set(idUnicos)];
      
      idUnicos.forEach(id=>{
        peticionesVentas.push({
          hashKey:{valor:id,nombre:"id"},
          sortKey:{valor:"VENTA#"+getSucursal(),operador:"eq",nombre:"type"},
        })
      })
      
      const lotes = dividirArray(peticionesVentas,8);
      for (let i = 0; i < lotes.length; i++) {
        const respVentas = await Promise.all(lotes[i].map(peticion=> getArrayAsync(peticion,[]) ))
        respVentas.forEach(venta=>{ if(venta){ ventas.push(venta[0])} });
        await new Promise(resolve=> setTimeout(resolve,300));
      }

      ventas.forEach(venta=>{
        newLineas = newLineas.map(linea=>{
          const newLinea = {...linea};
          if(linea.id==venta.id){
            newLinea.cliente = venta.cliente;
            newLinea.type = newLinea.type.replace("VENTA#PRODUCT#","");
            newLinea.shortId = venta.shortId;
          }
          return newLinea;
        });
      });

      newLineas.forEach(item=>{
        const cliente = item.cliente;
        if(!clienteAcomulado?.[item.cliente]){
          clienteAcomulado[cliente] ={ cliente: item.cliente, cantidadVendida:0,};
        }
        clienteAcomulado[cliente].cantidadVendida += item.cantidadVendida;
      })
      Object.keys(clienteAcomulado).forEach(key=>{ clienteTotal.push(clienteAcomulado[key]) })

      clienteTotal = OrderArrayZA(clienteTotal,"cantidadVendida","number");

      setValores(newLineas);
      setClientesTotal(clienteTotal);
      closemodalLoading();
    }catch (error){
      console.log('error=>'+error)
      closemodalLoading();
      handleError(error)
    }
  }


  useEffect(()=>{
    const getLineas=async()=>{
      const lineas = await useCatalogoAsync("LINEAS");
      if(lineas?.data?.opciones){setLineas(lineas.data.opciones);}
    };
    setOpenModalCalendar(true);
    getLineas();
  },[])

  /*useEffect(()=>{
    if(openModalCalendar){return}
    if(!fechaCalendar.fecha_Inicial||!fechaCalendar.fecha_Final){return}
    getVentasAsync();

  },[openModalCalendar,fechaCalendar])*/

  return (
    < >
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Obteniendo Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      
      {openModalCalendar?<CalendarModal state={fechaCalendar} setState={setFechaCalendar} openmodal={openModalCalendar} closemodal={setOpenModalCalendar}/>:""}

      <Modaldanger config={modalError} closeModal={closeModalError}>
        <Modaldanger.Message title={modalError?.title}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
          className="btn-gray2"
          tabIndex="200"
          onClick={closeModalError}>Cerrar</button>
        </Modaldanger.Buttons>
      </Modaldanger>
     

      <div className='flex flex-col gap-1 h-full'>
        <div className="w-full text-center font-bold">{"Reporte Ventas x Cliente x Linea "}</div>
        <div className="justify-center flex gap-2 items-center pt-2">
            <label className="font-semibold ">Linea:</label>
            <InputSelectormap
            catalogo={lineas}
            placeh="NOMBRE LINEA"
            name="linea"
            className={"w-44"}
            getvalor={(valor) => {
              //console.log(valor);
              setLinea(valor?.valor);
            }}/>
            <button
            className='btn-blue'
            onClick={(e)=>{
              getVentasAsync();
            }}>Calcular</button>

            <button
            className='btn-green'
            onClick={async(e)=>{
              const data = clientesTotal||[];
              //if(!data?.length){openModalError("No hay datos que exportar"); return;}
              const workb = await GenerateXlsx();
              if(workb?.error){
                closemodalLoading();
                handleError(workb.error);
                return
              }

              const nameFile="VENTAS-"+linea+"-CLIENTE",
              headerFile="VENTAS POR LINEA POR CLIENTE DEL "+fechaCalendar.fecha_Inicial+" AL "+fechaCalendar.fecha_Final,
              sheet1 = workb.sheet(0),sheet2=workb.addSheet("All"),colPlus = 4;

              sheet1.cell("A1").value(headerFile);
              sheet1.cell("A1").style("bold",true);
              sheet1.cell("A1").style("fontSize",18);

              const sizeData = data.length + colPlus,
              header={'Cliente':20,'Cantidad':10};
          
              Object.keys(header).map((key,index)=>{
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
                sheet1.column(columnasXlsx[index]).width(header[key]); 
              })

              data.forEach((venta,index) => {
                sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.cliente||"");
                sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.cantidadVendida||0);
              });

              sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");

              sheet2.cell("A1").value("Reporte Facturas Masivo ");
              sheet2.cell("A1").style("bold",true);
              sheet2.cell("A1").style("fontSize",18);
          
              const header2=['Cliente','Cantidad','Modelo','Ticket'],
              sizeColumn2=[20,10,30,12];
              let cont=0;
          
              header2.forEach((value,index)=>{
                sheet2.cell(columnasXlsx[index]+(colPlus-1)).value(value);
                sheet2.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
                sheet2.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
                sheet2.column(columnasXlsx[index]).width(sizeColumn2[index]);
              })

              valores.forEach((item,index) => {
                sheet2.cell(columnasXlsx[0]+(colPlus+cont)).value(item.cliente);
                sheet2.cell(columnasXlsx[1]+(colPlus+cont)).value(item.cantidadVendida||0);
                sheet2.cell(columnasXlsx[2]+(colPlus+cont)).value(item.type);
                sheet2.cell(columnasXlsx[3]+(colPlus+cont)).value(item.shortId);
                cont++;
              });

              sheet2.cell(columnasXlsx[1]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(cont+colPlus-1)+")");

              const response =await SaveXlsx(workb,nameFile);
              if(response?.error){
                closemodalLoading();
                handleError(response.error);
                return
              }
              closemodalLoading();
              openModalSuccess("Archivo generado correctamente","Descarga Completa");
            }}>Descargar</button>
        </div>
        <div className='w-full h-full relative'>
          <TableReactWindows
            columns={[
              { header: "Cliente", width:120, key: "cliente", type:tipoCelda.text, filter:true},
              { header: "Qty", width:80, key: "cantidadVendida", type:tipoCelda.number, filter:true,footer:true,function:getTotal},
            ]}
            data={clientesTotal}
          />
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteVentaClienteLinea)