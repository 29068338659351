import React from 'react'

const logofestivo = ({width,height,bg,corona,corona1,text,indigo}) => {
	const colores={corona: corona, corona1: corona1, letras:text }
	const styles = {
		st6: { fill: 'url(#SVGID_1_)', },
		st7: { fill: 'url(#SVGID_2_)' },
		st3: { fill: colores.letras},
    bolagorro: {fill:"#FFFFFF"},
    gorro: { fill: '#BA0B02' },
    bolaesfera:{fill:"#46cbff" },
    esfera:{fill:"#00B7FF"},
    esferacolgante:{fill:"#FFCE00"},
    estrella:{fill:"#FFCE00"}
	};

  return (
    <svg 
    className={height+" "+width+" "+bg+" "+" fill-current "}
    version="1.1"
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    viewBox="0 0 478.4 308.2" 
    style={{ enableBackground: 'new 0 0 478.4 308.2' }}
    space="preserve"
    >
  
    <g id="crown1" className={corona}>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="324.1992" y1="-1216.615" x2="249.7753" y2="-1390.8679" gradientTransform="matrix(1 0 0 -1 0 -1230)">
        <stop  offset="0.4134" style={{stopColor: colores.corona1 }} />
        <stop  offset="0.877" style={{stopOpacity:0, stopColor: colores.corona1, }}/>
      </linearGradient>
      <path style={styles.st6} id="CORONA2" d="M185.2,93.1
      C185.2,93.1,185.4,93.1,185.2,93.1L185.2,93.1z M185.9,92.9c-0.5-0.1-0.9,0.2-0.1,0.1C185.8,93,186.8,93,185.9,92.9z M323.3,171.5
      L323.3,171.5C323.4,171.5,323.4,171.4,323.3,171.5z M323.5,171.2L323.5,171.2C323.8,171.2,323.8,171.2,323.5,171.2z M186.3,92.9
      l20.7-5.5c16.2-4.2,45.6-9.6,62.6-9.8c1.4,0,1.6-0.2,3.1-0.2c19.5,0,41.9,0.8,57.3,13.3c7.6,6.2,12.8,14.3,12.8,24.8v4.4
      c0,13.2-7.1,29.2-12.5,40l-6.3,11.3c1.6-1.2,8.6-8.6,10-10.7l4.8-5.8c0.5-0.6,1.1-1.2,1.5-1.8l4.5-5.8c0.5-0.8,0.9-1.2,1.4-1.9
      c1.1-1.4,1.9-2.8,2.9-4.2c9-13.6,22.6-37.2,11.9-55.1c-0.4-0.6-0.8-1.2-1.2-1.7c-0.5-0.6-0.9-1.3-1.4-1.7c-0.5-0.4-1.2-1-1.6-1.3
      c-0.6-0.5-1.1-0.8-1.8-1.4c-1.3-1-2.1-1.7-3.5-2.5c-31.1-19-77.4-11.3-111.3-2.6c-18,4.6-35,10.4-52.2,17.3l-1.2,0.5
      C186.4,92.8,186.4,92.8,186.3,92.9z"/>
      
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="246.2682" y1="-1248.2894" x2="225.7515" y2="-1425.9316" gradientTransform="matrix(1 0 0 -1 0 -1230)">
        <stop  offset="0.4134" style={{stopColor: colores.corona }}/>
        <stop  offset="0.877" style={{stopOpacity:0, stopColor: colores.corona, }}/>
      </linearGradient>
      <path style={styles.st7} id="CORONA1" d="M10.6,169.9L10.6,169.9
      C10.8,170,10.6,169.9,10.6,169.9 M11.5,169.6c-0.1,0-1.2,0.4-0.1,0.4C11.6,169.8,12.2,169.6,11.5,169.6z M11.7,169.6
      c0.9-0.2,14-9.5,15.2-10.2c1.3-1,2.5-1.6,3.9-2.5c1.4-1,2.5-1.7,3.9-2.5c0.8-0.5,1.2-0.8,1.9-1.3l3.9-2.5
      c11.2-7.2,22.3-14.2,33.7-20.9c0.6-0.4,1.3-0.8,2-1.2l14.3-8.2c0.9-0.4,1.2-0.6,2-1.2l14.6-7.9c2.8-1.4,5.5-2.8,8.5-4.3
      c3.8-1.9,13.6-7.1,17.2-8.5c4.5-1.8,8.7-4.2,13.2-6l13.4-5.9c23-10.2,51.8-19.8,76.1-25.8c37-9.4,88.5-15.8,122.6,1.7
      c9.5,4.8,17.9,12.2,22.2,22.2c1.3,3.1,2.1,6,2.8,9.5c0.1,1.1,0.5,2.6,0.5,3.7c0.1,4.1,0.2,7.4-0.4,11.5c-0.8,4.6-1.8,9-3.4,13.3
      c-2.4,5.9-4.8,11.4-8,16.9c-2,3.6-4.2,7-6.5,10.2c-2.7,3.8-7.6,10.4-10.8,13.9l-4,4.4c-1.2,1.6-2.7,2.9-4.1,4.3
      c-3.1,3.1-6,6.6-9.3,9.4l-2,2c-0.1,0.1-0.4,0.4-0.5,0.5c-0.6,0.6-0.1-0.2-0.5,0.6h82c1.1-1.6,2.1-2.8,3.3-4.3
      c3.4-4.3,6.6-8.8,9.8-13.3c1.1-1.6,2-2.9,3.1-4.6l9.9-17.2l5.9-13.3c0.4-1.1,0.6-1.8,1.1-2.9c3.3-10.2,5.5-19.7,5.8-29.4v-4.1
      c-0.1-6.6-1.3-13.2-3.5-20.3c-0.9-2.9-2-5.8-3.5-8.3c-2.6-4.3-4.9-8.5-8.3-12.4c-0.5-0.6-0.9-0.8-1.4-1.6c-0.9-1.2-1.8-2-2.8-3
      c-0.6-0.5-0.9-0.6-1.5-1.3c-8.2-7.8-21.6-15-31.6-18.7C383.1,23.4,363.7,20,342.3,20c-18.3,0-30.7,1.1-48.3,3.6
      c-4.2,0.5-8.7,1.4-12.7,2c-8.3,1.6-16.1,3.2-24,5.4l-28.4,8.4c-13.2,4.4-24.8,8.9-37.5,14.2l-12.7,5.5c-10.9,4.8-21.6,10.4-32.3,16
      l-4.7,2.6c-4.1,2-7.9,4.4-11.9,6.6c-0.8,0.4-1.4,0.8-2.4,1.3c-4.7,2.6-9.3,5.5-13.9,8.3c-3.1,1.9-6,3.7-9,5.6
      c-1.5,1-3.1,1.8-4.5,2.9l-35.4,23.9c-5.8,4.1-11.3,8.5-16.8,12.7l-12.3,9.8c-0.8,0.6-1.3,1.1-2,1.7l-6.1,5.2
      c-1.4,1.2-2.6,2.3-4,3.4c-3.1,2.5-5.9,5.3-8.9,7.7C13.6,167.5,12.4,168.4,11.7,169.6z"/>
    </g>
    <ellipse id="BOLA" style={styles.bolaesfera} cx="426.7" cy="204.4" rx="48.5" ry="49.8"/>
    <g id="ARGOLLA" transform="translate(-370.75 -111.576)">
      <path id="ARGOLLA" style={styles.esferacolgante} d="M797.3,264.2c-7.6,0-13.9-6.5-13.9-14.3s6.2-14.3,13.9-14.3c7.6,0,13.9,6.5,13.9,14.3
		C811.3,257.9,805,264.2,797.3,264.2z M797.3,245.9c-2.1,0-3.9,1.8-3.9,4s1.8,4,3.9,4c2,0,3.9-1.8,3.9-4l0,0
		C801.3,247.6,799.4,245.9,797.3,245.9z"/>
      <path id="COSA1" style={styles.esferacolgante} d="M781.3,254.8h32.2c2.5,0,4.5,1.3,4.5,2.9v10.7c0,1.6-2,2.9-4.5,2.9h-32.2c-2.5,0-4.5-1.3-4.5-2.9
		v-10.7C776.8,256.1,778.7,254.8,781.3,254.8z"/>
    </g>
    <g id="letters" style={styles.st3}>
      <path id="I_0" className="st3" d="M3.3,252.4V137.7c0-3.8,3.1-6.9,6.9-6.9h16.5
      c3.8,0,6.9,3.1,6.9,6.9v114.7L3.3,252.4L3.3,252.4z"/>
      <path id="N" className="st3" d="M101.3,253.4v-50.6c0-12.4-4.2-18.1-13.9-18.1c-10.7,0-15.2,6-15.2,18.4v50.4H45.8v-91.3h24.8v9.7h0.2
		  c5.6-9.4,13.4-12.8,24.1-12.8c16,0,32.9,9.1,32.9,36.2v58.2H101.3L101.3,253.4z"/>
      <path id="D" className="st3" d="M206,253.4v-9.8h-0.2c-4.9,8.4-13.9,12.8-26.6,12.8c-26.9,0-44.4-21.2-44.4-48.8
		c0-27.2,18.1-48.5,44.1-48.5c10.2,0,18.6,3.1,26.1,10.9v-38.2h26.6v121.6H206L206,253.4z M206.2,207.5c0-12.6-9.4-22.8-22.6-22.8
		c-12.5,0-22.3,9.6-22.3,22.8c0,13.8,9.4,23.5,22.2,23.5C196.3,230.9,206.2,220.9,206.2,207.5z"/>
      <path id="I" className="st3" d="M242.5,253.4v-91.3H269v91.3H242.5z"/>
      <path id="star" style={styles.estrella} d="M260.1,101.8l6.2,12.6c0.4,0.9,1.3,1.5,2.2,1.6l13.9,2c2.4,0.4,3.4,3.3,1.6,5l-10,9.8
		c-0.7,0.7-1,1.7-0.9,2.6l2.4,13.8c0.4,2.4-2.1,4.3-4.3,3.1l-12.4-6.5c-0.9-0.5-1.9-0.5-2.8,0l-12.4,6.5c-2.2,1.1-4.7-0.7-4.3-3.1
		l2.4-13.8c0.2-1-0.2-1.9-0.9-2.6l-10-9.8c-1.8-1.7-0.8-4.7,1.6-5l13.9-2c1-0.1,1.8-0.7,2.2-1.6l6.2-12.6
		C255.9,99.6,259,99.6,260.1,101.8z"/>
      <path id="G" className="st3" d="M345.4,242.9c-4,8.6-13.9,13.7-24.8,13.7c-26,0-44.7-20.6-44.7-48c0-28,19-49.2,44.7-49.2
		c11.5,0,20.3,4.4,24.7,11.5h0.2v-8.5h26.6V244c0,12.4-1.5,21.6-4.8,28c-6.3,12.6-23.9,22.3-46.1,22.3c-25.1,0-41.5-14.5-43.6-32
		h30.8c3.1,6.7,8.9,9,15.6,9c13.4,0,21.6-9,21.6-25.7v-2.6H345.4z M345.6,208.2c0-13.2-8.8-23.5-21.7-23.5
		c-11.9,0-21.3,10.2-21.3,22.7c0,12.8,8.5,23.5,21.3,23.5C336.7,230.9,345.6,221.5,345.6,208.2z"/>
    </g>
    <g id="Franjas">
      <polygon style={styles.gorro} points="33.5,189.8 3.3,214 3.3,193.5 33.5,169.2 	"/>
      <polygon style={styles.gorro} points="33.5,152.2 3.3,176.5 3.3,156 33.5,131.7 	"/>
      <polygon style={styles.gorro} points="33.5,226.7 3.3,251 3.3,230.5 33.5,206.2 	"/>
    </g>
  </svg>
  )
}

  export default logofestivo