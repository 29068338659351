import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modalgeneric from "./modal/generic";
import Modalinfo from "./modal/info";
import Labelmoney from "../components/labelmoney";
import {useGetBalanceCliente} from "../Hooks/useApis";
import {CalculaPagos, getSucursal} from "../services/functions";
import TableModal from "./modal/TableModal";
import LabelFecha from "./LabelFecha";

const InputClientVenta = ({addClient,SetVentaAPagar, VentasCredito,Cliente,dark,Ruta}) => {
  const [dataclient, setdataclient] = useState([]);
  const [modalTablaConfig, setmodalTablaConfig] = useState(false);
  const [modalinfoConfig, setmodalinfoConfig] = useState({});

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
  }

  function calcularCreditoUsado(cuentasACredito) {
    let creditoUsado = 0;
    for (let cuenta of cuentasACredito) {
      creditoUsado = creditoUsado + (cuenta.total - cuenta.pagado);
    }
    return creditoUsado;
  }

  let input;
  const handleSuccessful = (data) => {
    
    input.value = "";

    if(data.cliente.sucursal===getSucursal()){
      if (data.message) {
        setmodalinfoConfig({
          isOpen: true,
          message: data.message,
        });
        addClient({});
      }

      const creditoUsado = calcularCreditoUsado(data.ventasCredito);
      const creditoRestante = data.cliente.limiteCredito - creditoUsado;

      let nuevoCliente = { ...data.cliente };
      nuevoCliente.creditoUsado = creditoUsado;
      nuevoCliente.creditoRestante = creditoRestante;

      addClient(nuevoCliente);
      if(Ruta.oldruta!=="venta"&&Ruta.oldruta!=="pedidoEspecial"){
        if (data.ventasPendientes?.length === 1) {
          let pagado = CalculaPagos(data.venta?.pagos||[]);
          const item = data.ventasPendientes[0];
          SetVentaAPagar({
            id: item.id,
            sucursal: item.sucursal,
            total: item.total - pagado,
            pagos: null,
            pagado: pagado,
            recibido:0,
            cambio:false,
            restante:0,
            importe: item.total,
            status: item.statusTemp
          });
        } else if (data.ventasPendientes?.length > 1) {
          setdataclient(data.ventasPendientes);
          setmodalTablaConfig({ isOpen: true });
        } else {
          SetVentaAPagar([]);
        }
        
      }
      VentasCredito(data.ventasCredito);
    }else{
      SetVentaAPagar([]);
      addClient({});
    }
  };

  const handleError = (error) => {
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

   useEffect(() => {
    if(!Cliente.id&&(Ruta.oldruta!=="venta"||Ruta.oldruta!=='pedidoEspecial')){return}
    input.value=Cliente.id;
    useGetBalanceCliente(Cliente.id.toUpperCase(),handleSuccessful,handleError);
  }, []) 
  

  return (
    <>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una venta por cobrar</Modalgeneric.Title>
          <TableModal
            columns={[
              { header: "Venta", accessor: "shortId" },
              { header: "Fecha", 
                    // eslint-disable-next-line react/display-name
                    code: (item) => (<LabelFecha>{item?.id||""}</LabelFecha>),
              },
              { header: "Vendedor", accessor: "nombreVendedor" },
              { header: "Numero de articulos", accessor: "numeroArticulos" },
              {
                header: "Total",
                // eslint-disable-next-line react/display-name
                code: (item) => <Labelmoney twoDecimal={true}>{item.total}</Labelmoney>,
              },
            ]}
            onclickRow={(item) => {
              let pagado = CalculaPagos(item?.pagos||[]);
              SetVentaAPagar({
                id: item.id,
                sucursal: item.sucursal,
                total: item.total - pagado,
                pagos: null,
                pagado: pagado,
                recibido:0,
                cambio:false,
                restante:0,
                importe: item.total,
                status: item.statusTemp
              });
              closeModaltabla();
            }}
            data={dataclient}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div>
        <form
          className={"form-horizontal items-center gap-1 size-form form-label-left"}
          onSubmit={(e) => {
            e.preventDefault();
            if (!input.value.trim()) {return;}
            useGetBalanceCliente(input.value.toUpperCase(),handleSuccessful,handleError);
          }}
        >
            <input
              type="text"
              className="border-form w-full uppercase"
              name="cliente"
              placeholder="Cliente"
              autoComplete="off"
              disabled={Cliente?.cotizado === true}
              autoFocus={true}
              ref={(node) => (input = node)}
              style={{ transition: "all .15s ease" }}
            />

            <button
              className={dark?"btn-blue1":"btn-gray2"}
              type="submit"
              disabled={Cliente?.cotizado === true}
              style={{ transition: "all .15s ease" }}
            >
              Buscar
            </button>
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  SetVentaAPagar: (datos) =>
    dispatch({
      type: "SET_VENTAAPAGAR",
      ventaapagar: datos,
    }),
  VentasCredito: (datos) =>
    dispatch({
      type: "SET_VENTACREDITO",
      ventascredito: datos,
    }),
});

const mapStateToProps = (state) => ({
  Cliente: state.ClientesVenta,
  Ruta:state.Ruta,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputClientVenta);
