import React from 'react'
import { FechaUTCToLocal, GetFechaActual, Sucursal, TransformSys } from "../../services/functions";

const HeaderTicket=({Ticket, textSize,copia })=> {
  const fecha = (Ticket?.id?Ticket.id:"");
  const textsize = textSize || "";

  return (<div className={"w-full "+textsize}>
    <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
    <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
    <p className="uppercase"> e-mail: {Sucursal('email')}</p>
    <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(fecha).fecha + " "+ FechaUTCToLocal(fecha).hora}</label> </p>
    <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.cliente)+" "+ Ticket?.nombreCliente}</label></p>
    <p className="uppercase">Vendedor: <label className="font-bold">{Ticket?.nombreVendedor}</label></p>
    {copia?
      <p className="uppercase">
        Fecha Imp: {(Ticket?.id?GetFechaActual().fecha:"")+" "+(Ticket?.id?GetFechaActual().hora:"")}
      </p>
    :""}
  </div>
  )
}

export default HeaderTicket