import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import ReporteAjuste from './Ajuste/ReporteAjuste';
import ReporteMerma from './Ajuste/ReporteMerma';
import ReporteComprasDay from './Compras/ReporteComprasDay';
import ReporteComprasFolio from './Compras/ReporteComprasFolio';
import HeaderReportes from './HeaderReportes';
import ReporteCategorias from './Inventario/ReporteCategorias';
import ReporteLinea from './Inventario/ReporteLinea';
import ReporteLineas from './Inventario/ReporteLineas';
import ReporteMarca from './Inventario/ReporteMarca';
import ReporteMarcaLinea from './Inventario/ReporteMarcaLinea';
import ReporteMarcas from './Inventario/ReporteMarcas';
import ReporteServicios from './Servicios/ReporteServicios';
import PanelReporteVentas from './Ventas/PanelReporteVentas';
import PanelReporteVentasDay from './Ventas/PanelReporteVentasDay';
import ReporteVentasFolio from './Ventas/ReporteVentasFolio';
import ReporteCash from './Cash/ReporteCash';
import ReporteAP from './AP/ReporteAP';
import ReporteAR from './AR/ReporteAR';
import AltaLineas from './AltaLineas';
import ReporteLineaCodigo from './Inventario/ReporteLineaCodigo';
import ReporteApDetail from './AP/ReporteApDetail';
import ReporteARDetail from './AR/ReporteARDetail';
import PanelReporteVentaProveedorLinea from './Ventas/PanelReporteVentaProveedorLinea';
import { setRutaRep } from '../../redux/Actions';
import ReporteTester from '../Tester';
import ReporteArOther from './ArOther/ReporteArOther';
import ReporteArOtherDetail from './ArOther/ReporteArOtherDetail';

const rutas = {
    ventasRango: <PanelReporteVentas/>,
    ventasDay: <PanelReporteVentasDay/>,
    ventasFolio: <ReporteVentasFolio/>,
    altaLineas:<AltaLineas/>,
    ventasProveedorLinea:<PanelReporteVentaProveedorLinea/>,

    inventarioCategoria:<ReporteCategorias/>,
    inventarioMarca:<ReporteMarca/>,
    inventarioLinea:<ReporteLinea/>,
    inventarioMarcas:<ReporteMarcas/>,
    inventarioMarcaLinea:<ReporteMarcaLinea/>,
    inventarioLineas:<ReporteLineas/>,
    inventarioServices:<ReporteServicios/>,
    inventarioAjuste:<ReporteAjuste/>,
    inventarioMerma:<ReporteMerma/>,
    inventarioLineaCodigo:<ReporteLineaCodigo/>,


    comprasDay: <ReporteComprasDay/>,
    comprasFolio: <ReporteComprasFolio/>,

    cashRango: <ReporteCash/>,
    tester: <ReporteTester/>,

    proveedorBalance: <ReporteAP/>,
    proveedorBalanceDia: <ReporteApDetail/>,
    clienteBalance: <ReporteAR/>,
    clienteBalanceDia: <ReporteARDetail/>,
    arotherBalance:<ReporteArOther/>,
    arotherDetail:<ReporteArOtherDetail/>,
}

const MenuReportes=({PropsReportes,addRutaRep,ruta,text,resetRep,setGlobal})=> {

    const rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

    const Card=({nombre,url,className})=>{
        return(
        <span className="pt-1 w-full pl-1 text-center">
            <div className={"cursor-pointer h-28 relative flex flex-col min-w-0 break-words hover:bg-gray-400 bg-white w-fullshadow-lg rounded-lg group text-blue-700 "+(className?className:"")}>
                <div
                className="px-4 py-6 flex-auto"
                onClick={(e) => {
                    addRutaRep(url);
                }}
                >
                    <p className=" h-full text-4xl group-hover:text-white font-bold">
                        {nombre}
                    </p>
                </div>
            </div>
        </span>
        )
    }

    useMemo(()=>{
        if(!ruta){
            resetRep();
            return;
        }
        addRutaRep(ruta);
        setGlobal(text);
    },[ruta])
   
    return <section className="h-full w-full">
        <HeaderReportes/>
        {!PropsReportes.rutas.length?
            <div className="sm:px-4 md:px-20 lg:px-60 pt-2 h-90/100">
            <div className="size-4-none">
                <Card nombre="Receiving" url="comprasDay" />
                <Card nombre="Inventory" url="inventarioCategoria" />
                <Card nombre="Sales" url="ventasRango"/>
                <Card nombre="Cash" url="cashRango" />
                <Card nombre="AP" url="proveedorBalance" />
                <Card nombre="AR" url="clienteBalance" />
                <Card nombre="Bank" url="compras" />
                <Card nombre="AP Other" url="compras" />
            </div>
            </div>
        :
        <div className="relative justify-start h-85/100 w-full" onContextMenu={e=>{e.preventDefault()}}>
            {rutas[rutaActualRep]}
        </div>   
        }
    </section>
}

const mapDispatchToProps = (dispatch) => ({
    addRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
    resetRep:()=>dispatch({type:"RESET_REP"}),
    setGlobal:(global)=>dispatch({type:"SET_GLOBAL_REP",global:global}),
  });
  const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
  });

export default connect(mapStateToProps, mapDispatchToProps)(MenuReportes);