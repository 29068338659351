import React from "react";
import { useEffect } from "react";

const Modalgeneric = (props) => {

  const handleKeyPress=(e)=>{
    if(e.key=="Escape"&&props?.config?.isOpen&&props?.closeModal){
      props.closeModal();
    }
  }

  useEffect(() => {
    if(!props.config?.isOpen){ return}
      const elements = document.getElementsByName("modalgeneric"); 
      const tab = props?.tab||"100";
      elements.forEach(element => {
        if(element.tabIndex==tab){
          element.focus();
        }
      });
  }, [props.config])

  return (
    <div tabIndex={"0"} className={"inset-0 h-full "+(props.config.isOpen ? "fixed " : "hidden ") + (props?.front?props.front:" z-30 ")+" "+props?.mt} onKeyDown={handleKeyPress}>
      <div className="align-middle p-0  md:px-4 px-2 text-center sm:p-0 max-h-6/6 overflow-y-auto h-full flex items-center">
        {/*
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    */}
        <div className={"fixed inset-0 transition-opacity "+props?.mt} aria-hidden="true">
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>


        {/*
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */}
        <div
          className={"flex flex-col justify-between mx-auto rounded-lg text-center overflow-hidden shadow-xl transform transition-all overflow-y-auto "+(props?.bg?props.bg:"bg-gray-50")+" "+(props?.w?props.w:" w-full")+" "+(props?.h?props.h:" h-full")}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};
// eslint-disable-next-line react/display-name
Modalgeneric.Buttons = (props) => (
  <div className={"sticky bottom-0 px-4 py-1 sm:px-6 sm:flex sm:flex-row-reverse w-full gap-2 bg-transparent"}>
    {props.children}
  </div>
);
// eslint-disable-next-line react/display-name
Modalgeneric.Body = (props) => (
  <div className={" px-1 md:px-2 w-full h-92/100 overflow-y-auto bg-transparent pt-1 text-center sm:mt-0 sm:text-left md:pt-3"}>
      {props.children}
  </div>
);
// eslint-disable-next-line react/display-name
Modalgeneric.Title = (props) => (
  <h3
    className={" w-full h-8 text-lg leading-6 font-bold text-center "+(props?.text?props.text:"text-gray-900")}
    id="modal-headline"
  >
    {props.children}
  </h3>
);

export default Modalgeneric;
