import { connect } from "react-redux";
import React, { useState,useEffect, useRef, useMemo } from "react";
import { agregarItemInventario, eliminarItemInventario, returnRuta } from "../../redux/Actions";
import { getSucursal, havePermission, moneyToValue, showMoney } from "../../services/functions";
import ContextMenu from "../modal/ContextMenu";
import Modalgeneric from "../modal/generic";
import InputPrecioPanel from "../InputPrecioPanel";
import Selectormap from "../Productos/Selectormap";
import Modaldanger from "../modal/danger";
import LoadingModal from "../modal/LoadingModal";
import { useCatalogo } from "../../Hooks/useApis";
import { permisos } from "../../static/dat/datos";

const TablaArmazonInvAux=({addInventario,linea,inventario,data,PropsInv,setPropsInventario,setInventario,delRuta,resetColors,Catalogos,addCatalogo})=>{

    const [metadata_tabla,setMetadata]=useState({}),
    [inventarioTotal,setInventarioTotal]=useState(0),
    [modalGeneric,setModalGeneric]=useState(false),
    [modalPrecioCompra,setModalPrecioCompra]=useState(false),
    [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
    [preciog,setPreciog]= useState(0),
    [modalMenu, setmodalMenu] = useState({}),
    [modalLoading, setmodalLoading] = useState({});

    const title = useRef("Sin Datos"),inputs =useRef({}),containerRef = useRef(null);
    const Armazones = Catalogos?.ARMAZONFORMAS ||[];
    const permisoUpdate = havePermission(permisos.altaProds);

    const validaSucursallEdit=()=>{
      if(PropsInv.sucursales[0]!==getSucursal()){
        setmodaldangererrorConfig({
          isOpen: true,
          message:  "Desbes cambiar la sucursal actual de: "+PropsInv.sucursales[0]+" a la sucursal "+getSucursal() ,
        });
        return false;
      }else{ return true}
    }  

    const getCantidad=(id)=>{
        let item = inventario.find(item=>item.id == id);
        return item?.cantidad || ""
    }
    const getitemOfInventario=(id)=>{
      let item = inventario.find(item=>item.id == id);
      return (item?{...item}:"")
    }
    const getitemOfPropsSucursal=(id)=>{
        let item = PropsInv.items.find(item=>item.id == id);
        //
        return (item?.sucursales[PropsInv.sucursales[0]]?{...item?.sucursales[PropsInv.sucursales[0]]}:"")
    }
    const getitemOfProps=(id)=>{
      let item = PropsInv.items.find(item=>item.id == id);
      return (item?{...item}:"")
    }
    const setCaracteristica=(campo,caract,valor,id)=>{
      let newInv = [...inventario];
      newInv.map(item=>{if(item.id==id){
        item[campo][caract]=valor
      }})
      setInventario(newInv);
    }

    function calculatotal() {
      let gTotal = 0;
      if(PropsInv?.tab=="IN"||PropsInv?.tab=="B") gTotal =inventario.filter(item=>(item.linea==linea)).reduce((a, b) => parseInt(a) + parseInt(b.cantidad), 0);
      else if(PropsInv?.tab=="G") gTotal =inventario.filter(item=>(item.linea==linea)).reduce((a, b) => parseInt(a) + parseInt(b.guideline), 0);
      else if(PropsInv?.tab=="O") gTotal =inventario.filter(item=>(item.linea==linea)).reduce((a, b) => parseInt(a) + parseInt((b.N>0?b.N:0)), 0);
      else gTotal =inventario.filter(item=>(item.linea==linea)).reduce((a, b) => parseInt(a) + parseInt(b.[PropsInv?.tab]||0), 0);
      setInventarioTotal(gTotal);
    }
    
    function ne (str){
      return str.replace(/\d+/g, n => n.padStart(8, "0"))
    }
    function byfila(a, b) {   
        return ne(a).localeCompare(ne(b));
    }

    function AddItems(item){
      return [
        <div key="0" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice&&!PropsInv?.groupPrice?"block":"hidden"))}
        onClick={e=>{
          setPropsInventario({...PropsInv,groupPrice:[],closeMenu:true});
          closemodalMenu();
        }}>
            Seleccionar Graduaciones
        </div>,
        <div key="1" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(!PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice?"block":"hidden"))}
        onClick={e=>{
          if(!PropsInv?.groupPrice){return;}
            openModalGeneric();
        }}>
          Cambiar Precio
        </div>,
        <label key="2" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems?(!PropsInv?.groupPrice?"hidden":"block"):"hidden")}
        onClick={e=>{
          if(!PropsInv?.groupPrice){return;}
          const props = {...PropsInv,closeMenu:true};
          delete props.groupPrice;
          delete props.selectItems;
          setPropsInventario(props); 
          closemodalMenu();
        }}>
          Cancelar
        </label>,
        <div key="3" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice?"block":"hidden"))}
        onClick={e=>{
          setPropsInventario({...PropsInv, globalPrice:true,oldInv:[...inventario],closeMenu:true});
          openModalGeneric();
        }}>
          <i className="fas fa-dollar-sign ml-1"></i>
          {" Cambio Precio General"}
        </div>,
        <div key="4" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.updatePrice?"block":"hidden")}
        onClick={e=>{
          validaSucursallEdit()?setPropsInventario({...PropsInv,editItems:true,ajuste:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true}):"";
        }}>
            Ajuste Inventario
        </div>,
        <div key="5" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="B"?"block":"hidden")}
        onClick={e=>{
          delRuta();
        }}>
          <i className="far fa-arrow-alt-circle-left text-blue-500"></i>
            {" Regresar a Compras asasas"}
        </div>,
        <div key="6" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.ajuste||PropsInv?.updatePrice?"block":"hidden")}
        onClick={e=>{
          if(PropsInv?.oldInv){
            console.log(PropsInv.oldInv)
            setInventario(PropsInv.oldInv);
          }
          const props ={...PropsInv,closeMenu:true};
          delete props.ajuste;
          props.editItems = false;
          delete props.inventarioAjuste;
          delete props.updatePrice;
          delete props.updatePriceBuy;
          delete props.globalPriceBuy;
          delete props.selectItems;
          delete props.groupPrice;
          delete props.oldInv;

          setPropsInventario(props);
        }}>
            {"Cancelar Ajuste"}
        </div>,
        <div key="7" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems?(PropsInv?.tab=="B"?"block":"hidden"):"hidden")}
        onClick={e=>{
          setPropsInventario({...PropsInv,enableImport:true,closeMenu:true});
        }}>
          <i className="fas fa-file-download "></i>
            {" Importar Formato Armazones"}
        </div>,
        <div key="8" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.editItems?(PropsInv.editItems==false?"block":"hidden"):"block")}
        onClick={e=>{
          setPropsInventario({...PropsInv,enableExport:true,closeMenu:true});
        }}>
          <i className="fas fa-file-upload "></i>
            {" Exportar Formato Armazon"}
        </div>,
        <div key="9" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.updatePrice?"block":"hidden")}
        onClick={e=>{
          validaSucursallEdit()?setPropsInventario({...PropsInv,updatePrice:true,inventarioAjuste:[],closeMenu:true}):"";
        }}>
            Actualizar Precio Venta
        </div>,
 
        <div key="10" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(permisoUpdate&&!PropsInv?.updateItem&&PropsInv?.tab=="G"?"block":"hidden")}
        onClick={e=>{
           let newInve = [...inventario]
           newInve.map(item=>{
            const itemProp =getitemOfProps(item.id);
            if(item.id==itemProp?.id){
              item.caracteristicas = itemProp.caracteristicas;
            }
           })
           setPropsInventario({...PropsInv,updateItem:true});
           setInventario(newInve);
        }}>
            Editar Producto
        </div>,
        <div key="11" 
         className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(permisoUpdate&&PropsInv?.updateItem&&PropsInv?.tab=="G"?"block":"hidden")}
         onClick={e=>{
            let newInve = [...inventario];
            newInve.map(item=>{
              delete item.caracteristicas; 
            })
            const props = {...PropsInv}
            delete props.updateItem;
            setPropsInventario(props);
            setInventario(newInve);
         }}>
             Cancelar Editar
        </div>,
        <div key="12" 
        className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="G"?(PropsInv.editItems==false?"block":"hidden"):"hidden")}
        onClick={e=>{
          setPropsInventario({...PropsInv,editItems:true,ajuste:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true});
        }}>
            Ajustar GuideLine
        </div>,
         <div key="13" 
         className={'font-semibold py-1 px-1 bg-red-200 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.globalPriceBuy?"block":"hidden")}
         onClick={e=>{
           validaSucursallEdit()?setPropsInventario({...PropsInv,globalPriceBuy:true,updatePriceBuy:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true}):"";
           openModalPrecioCompra();
         }}>
             Actualizar Precio Compra
         </div>,
      ]
    }
    
    function closeModalPrecioCompra(e) { setModalPrecioCompra({ isOpen: false });}
    function openModalPrecioCompra(e){ setModalPrecioCompra({ isOpen: true });}
    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, title :(titulo||"nothing") }); };
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });}
    function closeModalGeneric(e) {setModalGeneric({ isOpen: false });}
    function openModalGeneric(e){setModalGeneric({ isOpen: true });}
    const openmodalMenu = (e,item) => {
      const new_item = item || {};
      let positionY = e.clientY-(e.clientY-containerRef.current.getBoundingClientRect().y);
      let positionX = e.clientX-(containerRef.current.getBoundingClientRect().x<e.clientX?containerRef.current.getBoundingClientRect().x:0)+10;
      //console.log('x:',e.clientX,' y:',e.clientY,'wx:', window.screenX);
      if(e.clientY >containerRef?.current?.clientHeight){ positionY = e.clientY-350; }
      //console.log('y>>',e.clientY,' yN>>>',containerRef.current?.getBoundingClientRect().y)
     // console.log('x>>',e.clientY,' xN>>>',containerRef.current?.getBoundingClientRect().x)
      setmodalMenu({ isOpen: true, title: title.current, x: positionX, y: positionY, items: AddItems(new_item)});
    };
    const closemodalMenu = () => {setmodalMenu({ isOpen: false, title: "", x: 0, y: 0});};
    function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
    }

    const funcionError=(error)=>{
      closemodalLoading();
      setmodaldangererrorConfig({
        isOpen: true,
        message: (error||"")+" Error al cargar el catalogos",
      });
    }

    useMemo(()=>{
      if(!PropsInv?.idcompra){
        if(!inventario.length){setMetadata({});  return}
        if(JSON.stringify(metadata_tabla)!=="{}"){ return}
      }
      setMetadata(data);
    },[inventario])
    
    useMemo(() => {
        calculatotal();
    }, [inventario,PropsInv?.tab]);

    useEffect(() => {
      if(PropsInv?.closeMenu==true){
        closemodalMenu();
      }
    }, [PropsInv?.closeMenu])

    useEffect(() => {
      const props = {...PropsInv};
      delete props.groupPrice;
      delete props.selectItems;
      setPropsInventario(props); 
      closemodalMenu();
    }, [PropsInv?.tab])

    useEffect(()=>{
      let list = {...PropsInv.listprecios};
      const colores = {...PropsInv.colores};
      if(!inventario.length){resetColors();return;}
      inventario.map(item=>{
        if(!Object.keys(colores).find(color=>colores[color]==item.precioVenta)){
          const colors = Object.keys(colores);
          for(let i =0;i<colors.length;i++){
              if(colores[colors[i]]==""){
                colores[colors[i]]=item.precioVenta;
                list[item.precioVenta] = colors[i];
                break;
              }
          }
        }
      })
      setPropsInventario({...PropsInv, colores:colores,listprecios:list});
    },[inventario,PropsInv.data?.descripcion])

    useEffect(()=>{
      if(Catalogos?.ARMAZONFORMAS){return;}
      openmodalLoading("Cargando Catalogo");
      useCatalogo("ARMAZONFORMAS",(resp)=>{
        if(!resp?.opciones){
          funcionError();
          return;
        }
        addCatalogo({...Catalogos,ARMAZONFORMAS:resp.opciones.SS})
        //setArmazones(resp.opciones);
        closemodalLoading();
      },funcionError);
    },[])

    return (
    <>
    <LoadingModal config={modalLoading} >
      <LoadingModal.Body>
        <LoadingModal.Title>Cargado Movimientos</LoadingModal.Title>
      </LoadingModal.Body>
    </LoadingModal>
    <ContextMenu config={modalMenu} closemodalMenu={closemodalMenu}/>
    <Modaldanger config={modaldangererrorConfig}>
      <Modaldanger.Message title={"Error"}>
      {modaldangererrorConfig.message}
      </Modaldanger.Message>
      <Modaldanger.Buttons>
        <button
        type="button"
        className="btn-gray2 "
        onClick={closeModaldangererror} >
          Cerrar
        </button>
      </Modaldanger.Buttons>
    </Modaldanger>
    <Modalgeneric config={modalGeneric}>
    <Modalgeneric.Body>
      <Modalgeneric.Title>Precio venta </Modalgeneric.Title>
      <div className="flex flex-col w-full items-center">
        <div className="w-full md:w-24">
          <InputPrecioPanel
          form={true}
          min={PropsInv?.minPrecioVenta}
          setValor={(e)=>{ 
            setPreciog(moneyToValue(parseFloat(e)));
          }}/>
        </div>
      </div>
    </Modalgeneric.Body>
    <Modalgeneric.Buttons>
      <button
        type="button"
        disabled={(preciog<PropsInv?.minPrecioVenta)}
        onClick={e=>{
          let new_inventario = [];
          if(PropsInv?.globalPrice){
            inventario.forEach(item=>{
              new_inventario.push({...item,precioVenta:preciog});
            })
          }else{
            inventario.forEach(item=>{
              const index = PropsInv.groupPrice.indexOf(item.id);
              if(index>-1){
                new_inventario.push({...item,precioVenta:preciog});
              }else{
                new_inventario.push({...item})
              }
            })
          }
          setInventario(new_inventario);
          const props = {...PropsInv};
          delete props.groupPrice;
          delete props.selectItems;
          setPropsInventario(props);
          closeModalGeneric();
          /*let new_inventario = [...inventario];
          if(PropsInv?.globalPrice){
            new_inventario.map(item=>{
              item.precioVenta = preciog;
            })
          }else{
            new_inventario.map(item=>{
              const index = PropsInv.groupPrice.indexOf(item.id);
              if(index>-1){
                item.precioVenta = preciog;
              }
            });
          }
          setInventario(new_inventario);
          const props = {...PropsInv};
          delete props.groupPrice;
          delete props.selectItems;
          delete props.globalPrice;
          setPropsInventario(props);
          closeModalGeneric();*/
        }}
        className="btn-green"
      >
        Aplicar Precio Venta
      </button>
      <button
        type="button"
        onClick={closeModalGeneric}
        className="btn-gray1"
      >
        Cancelar
      </button>
    </Modalgeneric.Buttons>
  </Modalgeneric>
  <Modalgeneric config={modalPrecioCompra}>
    <Modalgeneric.Body>
      <Modalgeneric.Title>Precio Compra </Modalgeneric.Title>
      <div className="flex flex-col w-full items-center">
        <div className="w-full md:w-24">
          <InputPrecioPanel
          form={true}
          min={0}
          setValor={(e)=>{ 
            setPreciog(moneyToValue(parseFloat(e)));
          }}/>
        </div>
      </div>
    </Modalgeneric.Body>
    <Modalgeneric.Buttons>
      <button
        type="button"
        disabled={(preciog<0)}
        onClick={e=>{
          let new_inventario = [];
          if(PropsInv?.globalPriceBuy){
            inventario.forEach(item=>{
              new_inventario.push({...item,precioCompra:preciog});
            })
          }
          setInventario(new_inventario);
          const props = {...PropsInv};
          delete props.selectItems;
          setPropsInventario(props);
          closeModalPrecioCompra();
        }}
        className="btn-green"
      >
        Aplicar Precio Compra
      </button>
      <button
        type="button"
        onClick={closeModalPrecioCompra}
        className="btn-gray1"
      >
        Cancelar
      </button>
    </Modalgeneric.Buttons>
  </Modalgeneric>

    <div ref={containerRef} className="w-full px-2">
        <div className="mt-1 relative flex flex-col mb-1 break-words rounded bg-white border-0">
          <table >
            <thead>
              <tr className=" bg-bluei text-white text-sm md:text-base cursor-text align-middle pt-1 font-semibold text-center border border-gray-400">
                <th className='w-16'>{"Modelo"}</th>
                <th className={"w-16" }>Qty</th>
                <th className={"w-16" }>Diagonal</th>
                <th className={"w-16" }>Puente</th>
                <th className={"w-16" }>Varilla</th>
                <th className={"w-16" }>AltoMica</th>
                <th className={"w-36" }>Forma</th>
              </tr>
            </thead>

            <tbody>
              {(JSON.stringify(metadata_tabla)!=="{}")?
              Object.keys(metadata_tabla).sort(byfila).map((modelo, index1) => (
              <tr key={index1} className="cursor-text" >
                <td
                className={"w-16 px-2 bg-bluei text-white text-center font-semibold align-middle border border-gray-400 "}
                onClick={(e) => {}}
                >
                  {modelo}
                </td>
                <td className={"w-16 align-middle border border-black group "}>
                {PropsInv?.tab=="IN"||PropsInv?.tab=="B"?
                  <input
                  id={modelo}
                  type="number"
                  step='1'
                  min="0"
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none "+(PropsInv?.groupPrice?(PropsInv.groupPrice.find(item=>item==(linea+modelo))?"  bg-bluei text-white ":(PropsInv.listprecios[getitemOfInventario(linea+modelo)?.precioVenta])):(PropsInv.listprecios[getitemOfInventario(linea+modelo)?.precioVenta]))}
                  value={getCantidad(linea+modelo)}
                  readOnly={!PropsInv?.editItems}
                  onChange={e=>{
                    const getItemInv = getitemOfInventario(linea+modelo);
                    const getItemProps = getitemOfPropsSucursal(linea+modelo);
                    
                    if(PropsInv?.ajuste){
                      if(e.target.value==""){return;}
                      let invAjuste=[...PropsInv.inventarioAjuste];
                      let item = invAjuste.find(item=>item.id == modelo);

                      if(!item){
                        invAjuste.push(getItemInv); 
                        setPropsInventario({...PropsInv,inventarioAjuste:invAjuste})
                      }
                      addInventario({
                          id:linea+modelo,
                          cantidad: parseInt(e.target.value),
                          precioVenta:  getItemInv?.precioVenta||(getItemProps?.precioVenta>PropsInv?.minPrecioVenta?getItemProps?.precioVenta:0),
                          precioCompra: getItemInv?.precioCompra||getItemProps?.precioCompra||0,
                          descripcion:PropsInv.data.descripcion,
                          linea:linea,
                          type: PropsInv.data.linea,
                          marca: PropsInv.data.marca,
                          categoria: PropsInv.data.categoria,
                          comprable: getItemInv?.comprable||getItemProps?.comprable || true,
                          vendible: getItemInv?.vendible||getItemProps?.vendible || true,
                      });
                    }else{
                        e.target.value == 0?e.target.value = "":"";
                        addInventario({
                          id:linea+modelo,
                          cantidad:  (e.target.value == ""?0: parseInt(e.target.value)),
                          precioVenta: (getItemInv?.precioVenta?(getItemInv.precioVenta>PropsInv.minPrecioVenta?getItemInv.precioVenta:0):(getItemProps?.precioVenta?(getItemProps.precioVenta>PropsInv.minPrecioVenta?getItemProps.precioVenta:0):0)),
                          precioCompra: PropsInv.precioCompra ,
                          descripcion:PropsInv.data.descripcion,
                          linea:linea,
                          type: PropsInv.data.linea,
                          marca: PropsInv.data.marca,
                          categoria: PropsInv.data.categoria,
                          comprable: getItemInv?.comprable||getItemProps?.comprable || true,
                          vendible: getItemInv?.vendible||getItemProps?.vendible || true,
                          guideline: getItemProps?.guideline||0,
                        });
                    }
                  }}
                  onMouseEnter={e=>{
                    const getItemInv = getitemOfInventario(linea+modelo),ItemId= linea+modelo;
                    if(PropsInv?.groupPrice&&getItemInv?.id&&!PropsInv?.selectItems){
                      const props ={...PropsInv};
                      let list = [...PropsInv.groupPrice];
                      if(list.indexOf(ItemId)===-1){
                        list.push(ItemId);
                        if(PropsInv?.updatePrice==true){
                          let invAjuste=[...PropsInv.inventarioAjuste];
                          let item = invAjuste.find(item=>item.id == ItemId);
                          if(!item){
                            invAjuste.push(getItemInv); 
                            props.inventarioAjuste = invAjuste;
                          }
                        }
                      }else{
                        list = list.filter(item=> item !==(ItemId))
                        if(PropsInv?.updatePrice==true){
                          let invAjuste=[...PropsInv?.inventarioAjuste];
                          invAjuste=invAjuste.filter(inv=>inv.id!==ItemId)
                          props.inventarioAjuste = invAjuste;
                        }
                      }
                     
                      props.groupPrice = list;
                      setPropsInventario(props)
                    }
                  }}
                  onClick={e=>{
                    if(modalMenu?.isOpen==true){  closemodalMenu(); }
                    if(PropsInv?.groupPrice){
                      setPropsInventario({...PropsInv,selectItems:!PropsInv?.selectItems})
                    }
                  }}
                  onContextMenu={e=>{
                    openmodalMenu(e,getitemOfInventario(linea+modelo));
                  }}
                  />
                  :                  
                  <input
                  ref={e=>{inputs.current[linea+modelo+'o']=e}}
                  type="number"
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none "+(inputs.current[linea+modelo+'o']?.value<0?"text-red-400":"text-black")}
                  value={(PropsInv?.tab=="G"?getitemOfInventario(linea+modelo)?.guideline||"":(PropsInv?.tab=="O"?(getitemOfInventario(linea+modelo)?.N>0?getitemOfInventario(linea+modelo)?.N||"":""):(getitemOfInventario(linea+modelo)?.[PropsInv?.tab]||"")))}
                  readOnly={!PropsInv?.editItems}
                  onContextMenu={e=>{
                    openmodalMenu(e,getitemOfInventario(linea+modelo));
                  }}
                  onChange={e=>{
                    const itemID = linea+modelo;
                    const getItemInv = getitemOfInventario(itemID);
                    const getItemProps = getitemOfPropsSucursal(itemID);

                    if(PropsInv?.ajuste){
                      if(e.target.value==""){return;}
                      let invAjuste=[...PropsInv.inventarioAjuste];
                      let item = invAjuste.find(item=>item.id == itemID);

                      if(!item){
                        invAjuste.push(getItemInv); 
                        setPropsInventario({...PropsInv,inventarioAjuste:invAjuste})
                      }
                      addInventario({
                          id:linea+modelo,
                          guideline: parseInt(e.target.value),
                          cantidad: 0,
                          precioVenta:  getItemInv?.precioVenta||(getItemProps?.precioVenta>PropsInv?.minPrecioVenta?getItemProps?.precioVenta:0),
                          precioCompra: getItemInv?.precioCompra||getItemProps?.precioCompra||0,
                          descripcion:PropsInv.data.descripcion,
                          linea:linea,
                          type: PropsInv.data.linea,
                          marca: PropsInv.data.marca,
                          categoria: PropsInv.data.categoria,
                          comprable: getItemInv?.comprable||getItemProps?.comprable || true,
                          vendible: getItemInv?.vendible||getItemProps?.vendible || true,
                      });
                    }
                  }}
                  />
                }
                   <span className={(PropsInv?.tab=="IN"||PropsInv?.tab=="B"?(!PropsInv?.groupPrice?"tooltiptext":"hidden"):"hidden")}>
                     {showMoney(getitemOfInventario(linea+modelo)?.precioVenta)}
                  </span>
                </td>
                {!PropsInv?.updateItem?
                <>
                <td className={"w-16 font-bold text-center align-middle border bg-sky-100 border-gray-400 group "}>
                    {getitemOfProps(linea+modelo).caracteristicas?.largo}
                </td>
                <td className={"w-16 font-bold text-center align-middle border bg-sky-100 border-gray-400 group "}>
                    {getitemOfProps(linea+modelo).caracteristicas?.puente}
                </td>

                <td className={"w-16 font-bold text-center align-middle border bg-sky-100 border-gray-400 group "}>
                    {getitemOfProps(linea+modelo).caracteristicas?.varilla}
                </td>
                <td className={"w-16 font-bold text-center align-middle border bg-sky-100 border-gray-400 group "}>
                    {getitemOfProps(linea+modelo).caracteristicas?.alto}
                </td>
                <td className={"w-28 font-bold text-center align-middle border bg-sky-100 border-gray-400 group "}>
                    {getitemOfProps(linea+modelo).caracteristicas?.forma}
                </td>
                </>
                :
                <>
                <td className={"w-16 align-middle border bg-ambar-100 border-gray-500 group "}>
                  <input
                  type="number"
                  min={0}
                  step={1}
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none bg-transparent"}
                  readOnly={!PropsInv?.updateItem}
                  value={getitemOfInventario(linea+modelo).caracteristicas?.puente}
                  onChange={e=>{
                      setCaracteristica("caracteristicas","puente",(e.target.value==""||e.target.value=="0"?0:parseInt(e.target.value)),linea+modelo);
                  }}
                  />
                </td>
                <td className={"w-16 align-middle border bg-ambar-100 border-gray-500 group "}>
                  <input
                  type="number"
                  min={0}
                  step={1}
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none bg-transparent"}
                  readOnly={!PropsInv?.updateItem}
                  value={getitemOfInventario(linea+modelo).caracteristicas?.largo}
                  onChange={e=>{
                    if(e.target.value==""||e.target.value=="0"){return;}
                      setCaracteristica("caracteristicas","largo",parseInt(e.target.value),linea+modelo);
                  }}
                  />
                </td>
                <td className={"w-16 align-middle border bg-ambar-100 border-gray-500 group "}>
                  <input
                  type="number"
                  min={0}
                  step={1}
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none bg-transparent"}
                  readOnly={!PropsInv?.updateItem}
                  value={getitemOfInventario(linea+modelo).caracteristicas?.varilla}
                  onChange={e=>{
                    if(e.target.value==""||e.target.value=="0"){return;}
                      setCaracteristica("caracteristicas","varilla",parseInt(e.target.value),linea+modelo);
                  }}
                  />
                </td>
                <td className={"w-16 align-middle border bg-ambar-100 border-gray-500 group "}>
                  <input
                  type="number"
                  min={0}
                  step={1}
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none bg-transparent"}
                  readOnly={!PropsInv?.updateItem}
                  value={getitemOfInventario(linea+modelo).caracteristicas?.alto}
                  onChange={e=>{
                    if(e.target.value==""){return;}
                      setCaracteristica("caracteristicas","alto",parseInt(e.target.value),linea+modelo);
                  }}
                  />
                </td>
                <td className={"w-24 align-middle border border-gray-500 group "}>
                    <Selectormap
                    catalogo={Armazones}
                    defaultValue={getitemOfInventario(linea+modelo).caracteristicas?.forma}
                    getvalor={(valor) => {
                      if(valor.valor==""){return;}
                      setCaracteristica("caracteristicas","forma",valor.valor,linea+modelo);
                    }}
                    />
                </td>
                </>}
              </tr>
              ))
                :null}
            </tbody>

            <tfoot>
              <tr>
                <td></td>
              </tr>
              <tr>
                 <td className=" w-full" colSpan={"100"}>
                  <label className="w-full block font-bold text-xl text-center">{"Total: "+inventarioTotal}</label> 
                 </td> 
              </tr>
            </tfoot>
          </table>
        </div>
    </div>
    </>)
}

const mapStateToProps = (state) => ({
    inventario: state.Inventario,
    PropsInv: state.PropsInventario,
    Catalogos: state.Catalogos,
})
const mapDispatchToProps = dispatch => ({
    addInventario: (item) => dispatch(agregarItemInventario(item)),
    delInventario: (item) => dispatch(eliminarItemInventario(item)),
    setPrecioInventario:(esfera,precioVenta) => dispatch({type:"SET_PRECIOINVENTARIO",item:{precioVenta:precioVenta,esfera:esfera}}),
    setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
    setInventario:(items)=>dispatch({type:"SET_INVENTARIO",inventario:items}),
    addCatalogo: (element) =>dispatch({ type: "SET_CATALOGOS", catalogos: element }),

    resetColors:()=>dispatch({type:"RESET_PROPSCOLORS"}),
    delRuta:()=>dispatch(returnRuta()),
})


export default connect(mapStateToProps,mapDispatchToProps)(TablaArmazonInvAux)