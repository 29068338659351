import React from 'react'
import { connect } from 'react-redux'
import { FechaUTCToLocal, Sucursal, TransformSys } from '../../services/functions';
import LabelFecha from '../LabelFecha';
import Labelmoney from '../labelmoney';

export const BodyTicketPes = ({Ticket,textSize}) => {
  const fecha = (Ticket?.item?.id?Ticket.item.id.split('#')[1]:"");
  const textsize = textSize || "";

  return (
    <>
    <div className={"w-full "+textsize}>
        <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
        <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
        <p className="uppercase"> e-mail: {Sucursal('email')}</p>
        <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.item?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(fecha).fecha + " "+ FechaUTCToLocal(fecha).hora}</label> </p>
        <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.item?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.item?.nombrePersonal}</label></p>
    </div>
    <table className={"w-full text-2xs font-semibold mt-1 "+textsize}>
      <thead>
        <tr>
          <th className="text-center">Cant</th>
          <th className="text-center">Descripción</th>
          <th className="text-center w-12">Precio</th>
        </tr>
      </thead>
      <tbody>
      {Ticket?.item?.items
      ? (Ticket.item.items.map((product,index)=>{
        return <tr key={index}>
        <td className=" text-center">{product.cantidad}</td>
          <td className=" text-start text-3xs md:text-2xs">{product.descripcion}</td>
          <td className=" text-center "><Labelmoney twoDecimal={true}>{product.precioVenta}</Labelmoney></td>
      </tr>
      }))
      : null}
      </tbody>
      <tfoot>
        <tr>
          <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <div className={"flex flex-col w-full my-2 "+textsize}>
      <div className="grid grid-cols-3 w-full">
        <div className="font-bold text-start">Metodo</div>
        <div className="font-bold text-start">Monto</div>
        <div className="font-bold text-start">Fecha</div>
      </div>
      {Ticket?.pagos.length?
      <div className="w-full ">
        {Ticket.pagos.map((pago,index)=>(
          <div key={index} className="grid grid-cols-3 ">
            <label >{pago.metodo}:</label>
            <Labelmoney className="w-20" twoDecimal={true}>{(Ticket?.cambio==true&&pago.metodo=="EFECTIVO"?(Ticket.recibido || 0):(pago.monto || 0))}</Labelmoney>
            <LabelFecha>{pago.fecha}</LabelFecha>
          </div>
        ))}
      </div>
      :""
      }
      <div className={"grid grid-cols-3 w-full"+textsize}>
        <label>Importe:</label>
        <Labelmoney className="w-20" twoDecimal={true}>{Ticket?.item?.importe }</Labelmoney>
        <label></label>

        {Ticket?.pagos.length?
        <><label>Saldo:</label>
        <Labelmoney className="w-20" twoDecimal={true}>{((Ticket?.item?.importe || 0)-Ticket?.pagado)}</Labelmoney>
        <label></label></>
        :""}
      </div>
    </div>
    </> 
  );
};

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BodyTicketPes)